import React from 'react';

import * as Yup from 'yup';

import months from '../../data/months.json';
import FileInput from '../Form/FileInput';
import Input from '../Form/Input';
import Select from '../Form/Select';
import Form from '../Modal/ModalForm';

export default function AccountabilityModal(props) {
  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      // thumbnail: Yup.mixed().required('Selecione uma imagem'),
      file: Yup.mixed().test({
        name: 'file',
        message: 'Selecione um Arquivo',
        test: () => props.id || (!props.id && !!data.file),
      }),
      year: Yup.string().required('Informe um ano'),
      month: Yup.string().nullable().required('Selecione um mês'),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });
    return validate;
  };

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);
    formData.append('month', data.month);
    formData.append('year', data.year);
    return formData;
  };

  return (
    <Form
      title="Prestação de Contas"
      onFormData={handleFormData}
      onSchemaValidation={handleSchemaValidation}
      {...props}
    >
      <FileInput name="file" />
      <Input name="name" placeholder="Nome" />
      <Select
        name="month"
        label="Mês"
        options={months}
      />
      <Input name="year" placeholder="AAAA" />
    </Form>
  );
}
