/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Button } from 'antd';

import List from '../List';

export default function ListComponent(props) {
  return (
    <List
      {...props}
      onTitle={(user) => (user.isAdvocate ? `${user.name} - Advogado` : user.name)}
      onDescription={(user) => (user.email)}
      onActions={(user) => (
        [
          <Button
            type="primary"
            onClick={() => props.onOpen(user.id || user._id)}
          >
            Alterar
          </Button>,
          <Button
            type="danger"
            title={user.isAdvocate ? 'Não é possivel excluir advogado nesse gerenciador' : ''}
            onClick={() => props.onDelete(user.id || user._id)}
            disabled={user.isAdvocate}
          >
            Excluir
          </Button>,
        ]
      )}
    />
  );
}
