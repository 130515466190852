import React from 'react';

import List from '../../components/Associate/List';
import Modal from '../../components/Associate/Modal';
import Page from '../../components/DefaultManager';

export default function Associate() {
  return (
    <Page
      path="/associates"
      title="Parceiros"
      subTitle="Gerenciador de Parceiros"
      selectedKey="8"
      List={List}
      ModalForm={Modal}
    />
  );
}
