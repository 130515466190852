import React from 'react';
import {
  Container, Row, Col, ListGroup, Breadcrumb,
} from 'react-bootstrap';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';

export default function Institucional() {
  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Serviços</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1">
            <h1>Serviços</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mt-3 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="#"
                active
              >
                Serviços
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="http://www.oabsp.org.br/informacoesuteis/duvidasfrequentes/financeiro-anuidade"
              >
                Anuidades
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://cna.oab.org.br/"
              >
                Consulta CNA
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="/advogados-banco-oportunidade/A"
              >
                Banco de Oportunidades - Advogados
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="/advogados-banco-oportunidade/E"
              >
                Banco de Oportunidades - Estagiários
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="/advogados-banco-oportunidade/C"
              >
                Banco de Oportunidades - Correspondentes
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://examedeordem.oab.org.br/"
              >
                Exame de Ordem
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://www.oabsp.org.br/servicos/ac-oab"
              >
                Certificado Digital
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://esaoabsp.edu.br/"
              >
                ESA São Paulo
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://esa.oab.org.br/"
              >
                ESA Nacional
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://oabcaraguatatuba.com.br/@adv/"
              >
                Sistema Gestor
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="mt-5">
            <Container />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
