import React from 'react';

import Page from '../../components/DefaultManager';
import List from '../../components/VideoGallery/List';
import Modal from '../../components/VideoGallery/Modal';

export default function Gallery() {
  return (
    <Page
      path="/videos-gallery"
      title="Galeria de videos"
      subTitle="Gerenciador da Galeria de Videos"
      selectedKey="4"
      List={List}
      ModalForm={Modal}
    />
  );
}
