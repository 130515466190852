/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';

import SignIn from '../../pages/SignIn';
import { isAuthenticated } from '../../services/auth';

const PrivateRoute = (props) => {
  if (!isAuthenticated()) {
    return <Route path="/portal" element={<SignIn />} />;
  }

  return (
    <Route {...props} />
  );
};

export default PrivateRoute;
