import React, { useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import './styles.css';

function SocialMediaButtons() {
  useEffect(() => {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 60
        || document.documentElement.scrollTop > 60
      ) {
        const elem = document.getElementById('btn-social-media');
        if (elem) {
          elem.style.position = 'fixed';
          elem.style.top = '10px';
        }
      } else {
        const elem = document.getElementById('btn-social-media');
        if (elem) {
          elem.style.position = 'absolute';
          elem.style.top = '60px';
        }
      }
    };
  }, []);

  return (
    <div id="btn-social-media" className="d-flex btn-social-media">
      <a
        href="https://www.instagram.com/oab_caraguatatuba/?hl=pt-br"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button type="button" className="btn btn-instagram">
          <FiInstagram />
          <strong className="ml-2">Instagram</strong>
        </button>
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=5512981285462"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button type="button" className="btn btn-whatsapp">
          <FaWhatsapp />
          <strong className="ml-2">Whatsapp</strong>
        </button>
      </a>
      <a
        href="https://www.facebook.com/oabcaragua/?ref=br_rs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button type="button" className="btn btn-facebook">
          <FiFacebook />
          <strong className="ml-2">Facebook</strong>
        </button>
      </a>
    </div>
  );
}

export default SocialMediaButtons;
