/* eslint-disable no-param-reassign */
import axios from 'axios';

import { getToken, getIdKey } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const id = getIdKey();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.id = id;
  }
  return config;
});

export default api;
