import React, { useState, useEffect } from 'react';
import {
  Image, Container, Row, Col,
} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

// import io from 'socket.io-client';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import ShareButtons from '../../components/ShareButtons';
import api from '../../services/api';

const { REACT_APP_URL } = process.env;

export default function NoticeItem() {
  const { id } = useParams();
  const [notice, setNotice] = useState({});
  const navigate = useNavigate();

  /* useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL);
    socket.on('newnotice', (newNotice) => {
      // console.log(newNotice);
      // notification.success('Nova Notícia');
    });
  }, [navigate]); */

  useEffect(() => {
    const loadNotice = async () => {
      const res = await api.get(`/notices/${id}`);
      const { data } = res;
      if (data) {
        setNotice(res.data);
        return;
      }

      navigate('/');
    };

    loadNotice();
  }, [id, navigate]);

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <ShareButtons
          title="Compartilhar Notícia"
          url={`${REACT_APP_URL}/noticia/${notice.id}`}
        />
        <Row>
          <Col className="mt-5">
            <h1>{notice.title}</h1>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col xs={12} className="mt-3">
            {notice.thumbnail !== 'undefined' || !notice.thumbnail ? (
              <Image
                src={notice.thumbnail_url}
                alt={notice.thumbnail}
                style={{
                  width: '100%',
                  maxWidth: '500px',
                  float: 'left',
                  paddingRight: '20px',
                  paddingBottom: '10px',
                  borderRadius: '10px',
                }}
              />
            ) : (
              ''
            )}
            <h6
              style={{
                textAlign: 'justify',
              }}
            >
              {notice.description}
            </h6>
            <p
              style={{
                textAlign: 'justify',
              }}
            >
              {notice.content}
            </p>
            {notice.link && (
              <p>
                <a href={notice.link} target="_blank" rel="noopener noreferrer">
                  {notice.link}
                </a>
              </p>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
