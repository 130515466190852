import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14pt;
  color: #333;

  ::placeholder {
    color: #333;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14pt;
  color: #333;

  ::placeholder {
    color: #333;
  }
`;

export const Container = styled.div`
  margin: 10px 0;
  input[type="checkbox"] {
    margin: 10px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  padding: 5px;
`;

export const ImageInputContainer = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.preview});
  border: ${(props) => (props.preview ? '1px solid #ccc' : '1px dashed #ccc')};
  border-radius: 10px;
  cursor: pointer;
  img, span {
    display:  ${(props) => (props.preview ? 'none' : '')}
  }
`;

export const MsgError = styled.div`
  height: 25px;
  margin-left: 5px;
  color: #f00
`;
