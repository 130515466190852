import React from 'react';

import { FilePdfOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import List from '../List';

export default function ListComponent(props) {
  return (
    <List
      {...props}
      onTitle={(item) => `${item.month}/${item.year} - ${item.name}`}
      onDescription={(item) => item.file}
      onIcon={() => <FilePdfOutlined />}
      onActions={(item) => [
        <Button
          href={item.file_url}
          target="_blank"
          rel="noopener noreferrer"
          type="primary"
        >
          Ver Arquivo
        </Button>,
        <Button
          type="primary"
          onClick={() => props.onOpen(item.id || item._id)}
        >
          Alterar
        </Button>,
        <Button
          type="danger"
          onClick={() => props.onDelete(item.id || item._id)}
        >
          Excluir
        </Button>,
      ]}
    />
  );
}
