import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #cdcdcd;
  justify-content: center;
  padding: 50px 0;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 720px;
  background-color: #fff;
  overflow: hidden;

  img {
    width: 720px;
  }
`;

export const NoticesContainer = styled.div`
  padding: 50px;

  @media (max-width: 576px) {
    padding: 20px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
`;

export const MainNotice = styled.div`
  img {
    width: 100%;
  }

  h3 {
    color: #003768;
    font-weight: 700;
    margin: 20px 0;
  }

  @media (max-width: 576px) {
    h3 {
      font-size: 14pt;
    }

    p {
      font-size: 12pt;
    }
  }
`;

export const NoticeItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #ccc;

  div:first-child {
    width: 30%;
    margin-right: 20px;
  }

  div:last-child {
    width: 70%;
  }

  img {
    width: 100%;
  }

  h5 {
    color: #003768;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: justify;
  }

  @media (max-width: 576px) {
    flex-direction: column;

    div:first-child {
      width: 100%;
      margin-right: 0;
    }

    div:last-child {
      width: 100%;
    }

    h5 {
      font-size: 12pt;
      margin: 20px 0;
    }

    p {
      font-size: 10pt;
    }
  }
`;
