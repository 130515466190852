import React from 'react';

import { Container } from './styles';

const truncate = (text, max) => {
  if (text && text.length > max) {
    return `${text.substr(0, max - 1)}`;
  }
  return text;
};

function ReadMore({ children, link }) {
  return (
    <Container>
      {truncate(children, 200)}
      <a target="_blank" rel="noopener noreferrer" href={link}>
        {' '}
        ...Leia Mais
      </a>
    </Container>
  );
}

export default ReadMore;
