import React from 'react';

import List from '../../components/Associate/SubCategory/List';
import Page from '../../components/DefaultManager';

export default function Category() {
  return (
    <Page
      title="Subcategorias de Parceiros"
      subTitle="Gerenciador de Subcategorias de Parceiros"
      selectedKey="10"
      List={List}
    />
  );
}
