import React from 'react';

import Page from '../../components/DefaultManager';
import List from '../../components/User/List';
import Modal from '../../components/User/Modal';

export default function User() {
  return (
    <Page
      path="/users"
      title="Usuários"
      subTitle="Gerenciador de Usuários"
      selectedKey="6"
      List={List}
      ModalForm={Modal}
    />
  );
}
