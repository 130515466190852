import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';

export default function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const load = async () => {
      const res = await api.get('/posts');
      setPosts(res.data);
    };

    load();
  }, []);

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mt-5" fluid>
        <Outlet />
        <Row>
          {posts.map((item) => (
            <Col xs={12} md={4} key={item.id}>
              <Link
                to={`/blog/${item.id}`}
                style={{
                  textDecoration: 'none',
                  color: '#333',
                }}
              >
                <Card
                  style={{
                    height: '100%',
                    minHeight: '300px',
                    border: '0',
                  }}
                >
                  {item.thumbnail !== 'undefined' || !item.thumbnail ? (
                    <Card.Img
                      variant="top"
                      src={item.thumbnail_url}
                      style={{
                        borderRadius: '10px',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}
