import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, ListGroup, Form,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import AssociateCard from '../../../components/Cards/Associate';
import Footer from '../../../components/Footer';
import Header from '../../../components/Headers/Associate';
import Loader from '../../../components/Loading';
import NavBar from '../../../components/NavBars';
import AssociateNavBar from '../../../components/NavBars/Associate';
import api from '../../../services/api';
import localidadeApi from '../../../services/api.localidade';

function Category() {
  const { id } = useParams();
  const [associates, setAssociates] = useState([]);
  const [category, setCategory] = useState({});
  const [states, setStates] = useState([]);
  const [state, setState] = useState(0);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await localidadeApi.get('/estados');
      setStates(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (state) {
        setLoading(true);
        const res = await localidadeApi.get(`estados/${state}/municipios`);
        setCities(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
        setLoading(false);
      }
    })();
  }, [state]);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get(`/associate-category/${id}`);
        setCategory(res.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await api.get(
        `/associates-filter?category=${id}&state=${state}&city=${city}`,
      );
      setAssociates(res.data);
      setLoading(false);
    })();
  }, [id, city, state]);

  return (
    <div className="min-vh-100">
      <NavBar />
      <AssociateNavBar />
      <Header
        backgroundImage={
          category?.imgheader !== undefined ? category.imgheader_url : ''
        }
      >
        <h1>{category?.name}</h1>
      </Header>
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col md={4} className="mt-3 bg-white">
            <Form>
              <h5>Localidade</h5>
              <Form.Group controlId="state-select">
                <Form.Control
                  as="select"
                  size="lg"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option key={0} value={0}>
                    Estado
                  </option>
                  {states.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.nome}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Loader spinning={loading}>
                <Form.Group controlId="city-select">
                  <Form.Control
                    as="select"
                    size="lg"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option>Cidade</option>
                    {cities.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Loader>
            </Form>
            <h5>Subcategorias</h5>
            <ListGroup variant="flush">
              {category?.subcategories?.map((subcategory) => (
                <ListGroup.Item
                  key={subcategory._id}
                  className="bg-light text-dark"
                  variant="dark"
                  action
                  href={`/parceiros/categoria/${category.id}/subcategoria/${subcategory._id}`}
                >
                  {subcategory.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col className="mt-3">
            <Container className="justify-content-center row" fluid>
              {associates.map((associate) => (
                <AssociateCard
                  key={associate.id}
                  associate={associate}
                  states={states}
                  cities={cities}
                />
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Category;
