/* eslint-disable no-new */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Row, Col, ListGroup,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { notification } from 'antd';

import ModalForm from '../../../portal/components/Advocate/Modal';
import Advocate from '../../components/Cards/Advocate';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';

export default function Advocates() {
  const [showModal, setShowModal] = useState(false);
  const [advocate, setAdvocate] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await api.get(`/advocates/${id}`);
        const { data } = res;
        setAdvocate(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Falha!!!',
          description: 'Não foi possível consultar os dados do advogado',
        });
      }
    })();
  }, [id]);

  const handleCreate = useCallback((data) => {
    (async () => {
      try {
        await api.post('/advocates', data);
        notification.success({
          message: 'Cadastrado com sucesso!!!',
          description: 'Aguarde a aprovação para ser relacionado nas buscas',
        });
        setShowModal(false);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message && typeof message === 'string') {
            notification.error({
              message: 'Falha no Cadastro!!!',
              description: message,
            });

            return;
          }
        }

        notification.error({
          message: 'Falha no Cadastro!!!',
          description: 'Não foi possível realizar o cadastro',
        });
        setShowModal(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <>
        <NavBar />
        <Container className="justify-content-md-center mb-5" fluid="md">
          <h4>Carregando dados do Advogado</h4>
        </Container>
      </>
    );
  }

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-5">
            <h1>{`Advogado(a) ${advocate.nome}`}</h1>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-5 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                onClick={() => setShowModal(true)}
              >
                Cadastrar-se
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://api.whatsapp.com/send?phone=5512981285462"
                target="_blank"
              >
                Nosso Whatsapp
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://www.facebook.com/oabcaragua/?ref=br_rs"
                target="_blank"
              >
                Nosso Facebook
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://www.instagram.com/oab_caraguatatuba/?hl=pt-br"
                target="_blank"
              >
                Nosso Instagram
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <Container>
              <Advocate advocate={advocate} fullCard />
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ModalForm
        title="Cadastrar-se"
        visible={showModal}
        onCreate={handleCreate}
        resetForm={false}
      />
    </>
  );
}
