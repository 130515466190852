/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { List, Button, Avatar } from 'antd';

export default function ListComponent({
  list,
  loading,
  onOpen,
  onDelete,
  onTitle = (item) => item.title,
  onDescription = (item) => item.description,
  onThumbnail = (item) => (item?.thumbnail ? item?.thumbnail_url : null),
  onActions = (item) => (
    [
      <Button type="primary" onClick={() => onOpen(item.id || item._id)}>Alterar</Button>,
      <Button type="danger" onClick={() => onDelete(item.id || item._id)}>Excluir</Button>,
    ]
  ),
  onIcon,
}) {
  return (
    <List
      dataSource={list}
      loading={loading}
      itemLayout="horizontal"
      size="large"
      locale={{
        emptyText: 'Nenhum resultado encontrado',
      }}
      renderItem={(item) => (
        <List.Item
          key={item.id || item._id}
          style={{ borderColor: '#ccc' }}
          actions={onActions(item)}
        >
          <List.Item.Meta
            avatar={(
              <Avatar
                icon={onIcon ? onIcon(item) : null}
                src={onThumbnail(item)}
                style={{ backgroundColor: '#09f', verticalAlign: 'middle' }}
                shape="square"
                size="large"
              >
                {onIcon || onThumbnail
                  ? ' '
                  : onTitle(item)
                    ? onTitle(item).substr(0, 1).toUpperCase()
                    : ' '}
              </Avatar>
              )}
            title={onTitle(item)}
            description={onDescription(item)}
          />
        </List.Item>
      )}
    />
  );
}
