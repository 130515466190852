import React from 'react';

import List from '../../components/Advocate/List';
import Modal from '../../components/Advocate/Modal';
import Page from '../../components/DefaultManager';

export default function Advocate() {
  return (
    <Page
      path="/advocates"
      title="Advogados"
      subTitle="Gerenciador de Advogados"
      selectedKey="5"
      List={List}
      ModalForm={Modal}
    />
  );
}
