import React from 'react';

import { LinkOutlined } from '@ant-design/icons';

import List from '../List';

export default function VideoList(props) {
  return (
    <List
      {...props}
      onTitle={(item) => (
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.url}
        </a>
      )}
      onIcon={() => <LinkOutlined />}
    />
  );
}
