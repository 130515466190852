import React from 'react';
import {
  Container, Row, Col, ListGroup, Breadcrumb,
} from 'react-bootstrap';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';

export default function Institucional() {
  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Institucional</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1">
            <h1>Nossa História</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mt-3 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="#"
                active
              >
                Nossa História
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="http://www.oabsp.org.br/tribunal-de-etica-e-disciplina"
              >
                Tribunal Regional de Ética e Disciplina do Conselho Secional
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://www.oab.org.br/arquivos/resolucao-n-022015-ced-2030601765.pdf"
              >
                Código de Ética e Disciplina
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="http://www.oabsp.org.br/tribunal-de-etica-e-disciplina/regimento-interno/regimento-interno-ted-2019/"
              >
                Regimento Interno TED - SP
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://www.oab.org.br/jurisprudencia/ementarios"
              >
                Conselho Federal do Ementário
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://www2.oabsp.org.br/asp/tribunal_etica/tedEmentarios.asp"
              >
                Conselho Secional do Ementário
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="http://www.planalto.gov.br/ccivil_03/leis/l8906.htm"
              >
                Tabela de Honorários Advocatícios
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="https://www.oab.org.br/leisnormas/legislacao?provimento=1"
              >
                Conselho Federal da Legislação
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                target="blank"
                href="http://www.oabsp.org.br/tribunal-de-etica-e-disciplina/legislacao"
              >
                Conselho Secional da Legislação
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="mt-5">
            <Container />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
