import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Container, Input, MsgError } from './styles';

export default function InputComponent({ name, ...rest }) {
  const inputRef = useRef(null);
  const {
    fieldName, defaultValue = '', registerField, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {error ? <MsgError>{error}</MsgError> : null}
      <Input
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </Container>
  );
}
