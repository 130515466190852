import React, { useState } from 'react';

import { Tabs } from 'antd';
import * as Yup from 'yup';

import Checkbox from '../Form/Checkbox';
import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import ModalForm from '../Modal/ModalForm';

export default function Modal(props) {
  const [previewUrl, setPreviewUrl] = useState(null);

  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    name: Yup.string().required('O nome é obrigatório'),
    email: Yup.string()
      .email('Informe um email válido')
      .required('O email é obrigatório'),
    password: Yup.string()
      .min(6, 'Informe pelo menos 6 caracteres')
      .required('A senha é obrigatória'),
  });

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('thumbnail', data.thumbnail);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('permissionStore', data.permissionStore);
    formData.append('permissionUpdate', data.permissionUpdate);
    formData.append('permissionDelete', data.permissionDelete);

    return formData;
  };

  return (
    <ModalForm
      title="Usuário"
      schemaValidation={schemaValidation}
      onPreview={setPreviewUrl}
      onFormData={handleFormData}
      {...props}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Geral" key="1">
          <ImageInput
            name="thumbnail"
            previewUrl={previewUrl}
          />
          <Input
            name="name"
            placeholder="Nome"
          />
          <Input
            name="email"
            type="email"
            placeholder="Email"
          />
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            hidden={props.id}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Permissões do Usuário"
          key="2"
          forceRender
        >
          <Checkbox
            name="permissionStore"
            type="checkbox"
            label="Criar"
          />
          <Checkbox
            name="permissionUpdate"
            type="checkbox"
            label="Alterar"
          />
          <Checkbox
            name="permissionDelete"
            type="checkbox"
            label="Excluir"
          />
        </Tabs.TabPane>
      </Tabs>
    </ModalForm>
  );
}
