import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 10px 0;
`;

export const Label = styled.div`
  display: inline-block;
  padding: 5px 0;
  margin-left: 5px;
  font-size: 8pt;
  font-weight: 600;
  color: #333;
`;

export default Container;
