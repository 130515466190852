/* eslint-disable camelcase */
import React from 'react';
import {
  FiFacebook, FiInstagram, FiPhone, FiMail, FiLink,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import especialidades from '../../../../portal/data/especialidades.json';
import { Container, Icons } from './styles';

function Advocate({
  advocate,
  onEspecialidadeClick = () => null,
  onClickTo = `/advogados/${advocate._id}`,
  fullCard,
}) {
  if (fullCard) {
    return (
      <Container>
        <div>
          <strong>OAB: </strong>
          {advocate.oab}
          <br />
          <strong>Atuações: </strong>
          {advocate.especialidade?.map((id) => (
            <span
              key={id}
              role="button"
              onClick={() => onEspecialidadeClick(id)}
              onKeyPress={() => onEspecialidadeClick(id)}
              tabIndex={id}
            >
              {`${
                especialidades.find((especialidade) => especialidade.id === id)
                  .nome
              } `}
            </span>
          ))}

          {advocate?.especialidade_oficial?.length > 0 ? (
            <>
              <br />
              <strong>Especialidades: </strong>
              {advocate.especialidade_oficial?.map((id) => (
                <span
                  key={id}
                  role="button"
                  tabIndex={id}
                >
                  {`${
                    especialidades.find(
                      (especialidade) => especialidade.id === id,
                    ).nome
                  } `}
                </span>
              ))}
            </>
          ) : null}
        </div>
        <Icons>
          {advocate.whatsapp && (
            <div>
              <FiPhone size={18} color="#34af23" />
              <a
                title="Whatsapp"
                href={`https://api.whatsapp.com/send?phone=55${advocate.whatsapp}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {advocate.whatsapp}
              </a>
            </div>
          )}
          {advocate.facebook && (
            <div>
              <FiFacebook size={18} color="#3b5998" />
              <a
                title="Facebook"
                href={advocate.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                {advocate.facebook}
              </a>
            </div>
          )}
          {advocate.instagram && (
            <div>
              <FiInstagram size={18} color="#d10869" />
              <a
                title="Instagram"
                href={advocate.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                {advocate.instagram}
              </a>
            </div>
          )}
          {advocate.site && (
            <div>
              <FiLink size={18} color="#ed943a" />
              <a
                title="Site"
                href={advocate.site}
                target="_blank"
                rel="noopener noreferrer"
              >
                {advocate.site}
              </a>
            </div>
          )}
          {advocate.email && (
            <div>
              <FiMail size={18} color="#222" />
              {advocate.email}
            </div>
          )}
          {advocate.telefones && (
            <div>
              <FiPhone size={18} color="#222" />
              {advocate.telefones}
            </div>
          )}
        </Icons>
        {advocate.cep && advocate.endereco && (
          <>
            <br />
            <strong>Escritório: </strong>
            {`${advocate.endereco}, ${advocate.numero}, ${
              advocate.complemento ? `${advocate.complemento},` : ''
            } ${advocate.bairro}, ${advocate.cidade}, ${advocate.estado} - ${
              advocate.cep
            }`}
            <br />
            <br />
          </>
        )}
        {advocate.descricao && <p>{advocate.descricao}</p>}
      </Container>
    );
  }

  return (
    <Container>
      <Link to={onClickTo}>
        <h5>{advocate.nome}</h5>
      </Link>
      <span>
        {advocate.especialidade.map((id) => (
          <span
            key={id}
            role="button"
            onClick={() => onEspecialidadeClick(id)}
            onKeyPress={() => onEspecialidadeClick(id)}
            tabIndex={id}
          >
            {`${
              especialidades.find((especialidade) => especialidade.id === id)
                .nome
            } `}
          </span>
        ))}
      </span>
      <Icons>
        {advocate.whatsapp && (
          <div>
            <FiPhone size={18} color="#34af23" />
            <a
              title="Whatsapp"
              href={`https://api.whatsapp.com/send?phone=55${advocate.whatsapp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {advocate.whatsapp}
            </a>
          </div>
        )}
        {advocate.facebook && (
          <div>
            <FiFacebook size={18} color="#3b5998" />
            <a
              title="Facebook"
              href={advocate.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              {advocate.facebook}
            </a>
          </div>
        )}
        {advocate.instagram && (
          <div>
            <FiInstagram size={18} color="#d10869" />
            <a
              title="Instagram"
              href={advocate.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              {advocate.instagram}
            </a>
          </div>
        )}
        {advocate.site && (
          <div>
            <FiLink size={18} color="#ed943a" />
            <a
              title="Site"
              href={advocate.site}
              target="_blank"
              rel="noopener noreferrer"
            >
              {advocate.site}
            </a>
          </div>
        )}
        {advocate.email && (
          <div>
            <FiMail size={18} color="#222" />
            {advocate.email}
          </div>
        )}
      </Icons>
      {advocate.descricao && <p>{advocate.descricao}</p>}
    </Container>
  );
}

export default Advocate;
