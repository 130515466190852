import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import {
  FiFacebook, FiPhone, FiInstagram,
} from 'react-icons/fi';

export default function Footer() {
  return (
    <footer className="py-5 bg-dark">
      <Container fluid>
        <Row className="mb-5 justify-content-md-center">
          <Container style={{
            maxWidth: '200px',
          }}
          >
            <Row className="justify-content-md-center">
              <Col className="my-2 text-white">
                <a
                  title="Facebook"
                  href="https://www.facebook.com/oabcaragua/?ref=br_rs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiFacebook size={36} color="#fff" />
                </a>
              </Col>
              <Col
                className="my-2 text-white"
              >
                <a
                  title="Instagram"
                  href="https://www.instagram.com/oab_caraguatatuba/?hl=pt-br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiInstagram size={36} color="#fff" />
                </a>
              </Col>
              <Col className="my-2 text-white">
                <a
                  title="Whatsapp"
                  href="https://api.whatsapp.com/send?phone=5512981285462"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiPhone size={36} color="#fff" />
                </a>
              </Col>
            </Row>
          </Container>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <p className="m-0 text-center text-white">
              Avenida Paulo Ferraz da Silva Porto, 377, Caraguatatuba, SP, CEP 11.661-570
            </p>
            <p className="m-0 text-center text-white">
              (12) 3882.2185
            </p>
            <p className="m-0 text-center text-white">
              caraguatatuba@oabsp.org.br
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
