import React from 'react';

import Modal from '../../components/Blog/Modal';
import Page from '../../components/DefaultManager';
import List from '../../components/List';

export default function Blog() {
  return (
    <Page
      path="/posts"
      title="Blog"
      subTitle="Gerenciador de Posts"
      selectedKey="1"
      List={List}
      ModalForm={Modal}
    />
  );
}
