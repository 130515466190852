/* eslint-disable no-new */
import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';

import { notification } from 'antd';

import Advocate from '../../components/Cards/Advocate';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function AdvocateOpportunityDesk() {
  const [advocate, setAdvocate] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await api.get(`/advocateOpportunityDesks/${id}`);
        const { data } = res;
        setAdvocate(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Falha!!!',
          description: 'Não foi possível consultar os dados do advogado',
        });
      }
    })();
  }, [id]);

  if (loading) {
    return (
      <>
        <NavBar />
        <Container className="justify-content-md-center mb-5" fluid="md">
          <h4>
            Carregando dados do
            {' '}
            {`${advocate.isEstagiario ? 'Estagiário(a)' : 'Advogado(a)'}`}
          </h4>
        </Container>
      </>
    );
  }

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-5">
            <h1>
              {`${advocate.isEstagiario ? 'Estagiário(a)' : 'Advogado(a)'} ${
                advocate.nome
              }`}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Advocate advocate={advocate} fullCard />
          </Col>
        </Row>
        {advocate.curriculoFile ? (
          <>
            <Row>
              <Col>
                <Document
                  file={advocate.curriculo_file_url}
                  error="Não foi possível carregar o pdf"
                  loading="Carregando Pdf"
                  onLoadSuccess={({ numPages }) => {
                    setPages(numPages);
                  }}
                >
                  <Page pageNumber={page} />
                </Document>
              </Col>
            </Row>
            {pages && (
              <Row className="justify-content-between">
                <Button
                  variant="dark"
                  onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                >
                  {'<'}
                </Button>
                <small>{`Página ${page} de ${pages}`}</small>
                <Button
                  variant="dark"
                  onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                >
                  {'>'}
                </Button>
              </Row>
            )}
          </>
        ) : null}
      </Container>
      <Footer />
    </>
  );
}
