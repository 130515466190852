import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, notification } from 'antd';

import api from '../../../services/api';
import { ButtonContainer } from '../../DefaultManager/styles';
import List from '../../List';
import ModalForm from './Modal';

export default function SubCategoryList(props) {
  const { categoryId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await api.get(`/associate-category/${categoryId}`);
        setSubCategories(res.data.subcategories);
        setCategory(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, [categoryId]);

  const handleOpen = (itemId) => {
    setId(itemId);
    setShowModal(true);
  };

  const handleClose = () => {
    setId(null);
    setShowModal(false);
  };

  const handleCreate = async (data) => {
    try {
      const res = await api.post('associate-subcategory', data);
      setSubCategories(res.data);
      handleClose();
      notification.success({
        message: 'Mensagem!!!',
        description: 'Cadastrado com sucesso!!!',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (data) => {
    try {
      const res = await api.put(`associate-subcategory/${id}`, data);
      setSubCategories(res.data);
      handleClose();
      notification.success({
        message: 'Mensagem!!!',
        description: 'Alterado com sucesso!!!',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const res = await api.delete(`/associate-subcategory/${itemId}`);
      setSubCategories(subCategories.filter((value) => (value.id || value._id) !== itemId));
      notification.success({
        message: 'Mensagem!!!',
        description: res.data.message || 'Excluido com sucesso',
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h5>
        {`Categoria: ${category.name !== undefined ? category.name : ''}`}
      </h5>
      <ButtonContainer>
        <Button type="primary" onClick={() => handleOpen(null)}>
          Adicionar
        </Button>
      </ButtonContainer>
      <List
        {...props}
        loading={loading}
        list={subCategories}
        onTitle={(item) => item.name}
        onActions={(item) => [
          <Button
            type="primary"
            onClick={() => handleOpen(item.id || item._id)}
          >
            Alterar
          </Button>,
          <Button
            type="danger"
            onClick={() => handleDelete(item.id || item._id)}
          >
            Excluir
          </Button>,
        ]}
      />
      <ModalForm
        id={id}
        path="/associate-subcategory"
        visible={showModal}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onClose={handleClose}
      />
    </>
  );
}
