import React from 'react';

import { Container } from './styles';

function Associate({ children, backgroundImage }) {
  return (
    <Container src={backgroundImage}>
      {children}
    </Container>
  );
}

export default Associate;
