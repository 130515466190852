/* eslint-disable no-shadow */
import React, { useRef, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { Button, Space, notification } from 'antd';
import * as Yup from 'yup';

import Loader from '../../../app/components/Loading';
import api from '../../services/api';
import Modal from './Modal';
import { Content } from './styles';

export default function ModalForm({
  id,
  title,
  visible,
  path,
  schemaValidation,
  children,
  resetForm = true,
  onClose,
  onCloseModal,
  onCreate,
  onUpdate,
  onPreview,
  onFormData = (data) => data,
  addData,
  onSetFieldValue,
  onSchemaValidation,
}) {
  const [loading, setLoading] = useState(true);
  const formRef = useRef(null);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      if (formRef.current) {
        formRef.current.setErrors({});
        formRef.current.reset();
      }

      if (id) {
        const res = await api.get(`${path}/${id}`);
        formRef.current.setData(res.data);
        if (onPreview) {
          onPreview(res.data.thumbnail_url);
        }
        if (onSetFieldValue) {
          onSetFieldValue(formRef, res.data);
        }
      }
      setLoading(false);
    };

    loadData();
  }, [id, onPreview, path, onSetFieldValue]);

  useEffect(() => {
    if (addData) {
      formRef.current.setData(addData);
    }
  }, [addData]);

  const handleSubmit = async (data, { reset }) => {
    try {
      setLoading(true);
      // validation
      if (onSchemaValidation) {
        await onSchemaValidation(data);
      } else if (schemaValidation) {
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
      }

      // Validation passed
      const formData = onFormData(data);
      if (id) {
        await onUpdate(formData);
      } else {
        await onCreate(formData);
      }

      formRef.current.setErrors({});
      if (resetForm) {
        reset();
      }
      setLoading(false);

      if (onClose) {
        onClose();
      }
    } catch (err) {
      setLoading(false);
      // Validation failed
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        notification.error({
          message: 'Problema na validação!!!',
          description: 'Alguns campos precisam ser preenchidos',
        });
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal title={title} visible={visible} onClose={onCloseModal || onClose} footer={() => null}>
      <Loader spinning={loading}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>{children}</Content>
          <Space>
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Space>
        </Form>
      </Loader>
    </Modal>
  );
}
