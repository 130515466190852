import React, { useCallback } from 'react';

import { Button, notification } from 'antd';

import api from '../../services/api';
import List from '../List';

export default function ListComponent(props) {
  const handleApproved = useCallback((id) => {
    try {
      (async () => {
        const response = await api.put(`/advocateOpportunityDesks/${id}`, {
          approved: true,
        });
        const { model } = response.data;
        props.onUpdate(model, id);
        notification.success({
          message: 'Mensagem',
          description: 'Cadastro aprovado com sucesso',
        });
      })();
    } catch (error) {
      notification.error({
        message: 'Erro!!!',
        description: 'Não foi possível aprovar, Tente novamente',
      });
    }
  }, [props]);

  return (
    <List
      {...props}
      onTitle={(item) => item.nome}
      onDescription={(item) => `Número da inscrição oab ${item.oab}`}
      onActions={(item) => [
        item.approved ? null : (
          <Button onClick={() => handleApproved(item.id || item._id)}>
            Aprovar
          </Button>
        ),
        <Button
          type="primary"
          onClick={() => props.onOpen(item.id || item._id)}
        >
          Alterar
        </Button>,
        <Button
          type="danger"
          onClick={() => props.onDelete(item.id || item._id)}
        >
          Excluir
        </Button>,
      ]}
    />
  );
}
