/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';

import { Button, notification } from 'antd';

import api from '../../services/api';
import Page from '../DefaultPage';
import { ButtonContainer } from './styles';

export default function DefaultManager({
  path,
  title,
  subTitle,
  addButtonName = 'Adicionar',
  headerDescription,
  selectedKey,
  children,
  List,
  ModalForm,
}) {
  const [showModal, setShowModal] = useState(false);
  const [models, setModels] = useState([]);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);

  const handlerError = (error) => {
    if (error && error.response) {
      const { message } = error.response.data;
      console.log(error);
      if (message) {
        notification.error({
          message: 'Erro!!!',
          description: message,
        });
      }
    }

    console.log(error);
  };

  useEffect(() => {
    async function loadModels() {
      try {
        if (path) {
          setLoading(true);
          const res = await api.get(path);
          console.log(res.data);
          setModels(res.data);
          setLoading(false);
        }
      } catch (error) {
        handlerError(error);
      }
    }

    loadModels();
  }, [path]);

  const handleOpen = (modelId) => {
    setId(modelId);
    setShowModal(true);
  };

  const handleClose = () => {
    setId(null);
    setShowModal(false);
  };

  const handleAddModel = (newModel) => {
    setModels((state) => (
      [newModel, ...state]
    ));
  };

  const handleUpdateModelList = (newModel, modelId) => {
    setModels((state) => [
      newModel,
      ...state.filter((value) => (value.id || value._id) !== modelId),
    ]);
  };

  const handleCreate = async (data) => {
    try {
      const res = await api.post(path, data);
      const { model, message } = res.data;
      setModels([model, ...models]);
      notification.success({
        message: 'Mensagem!!!',
        description: message || 'Cadastrado com sucesso!!!',
      });
    } catch (error) {
      handlerError(error);
    }
  };

  const handleUpdate = async (data) => {
    try {
      const res = await api.put(`${path}/${id}`, data);
      const { model, message } = res.data;
      setModels([model, ...models.filter((value) => ((value.id || value._id) !== id))]);
      notification.success({
        message: 'Mensagem!!!',
        description: message || 'Alterado com sucesso!!!',
      });
    } catch (error) {
      handleClose();
      handlerError(error);
    }
  };

  const handleDelete = async (modelId) => {
    try {
      const res = await api.delete(`${path}/${modelId}`);
      setModels(models.filter((value) => ((value.id || value._id) !== modelId)));
      notification.success({
        message: 'Mensagem!!!',
        description: res.data.message || 'Excluido com sucesso',
      });
    } catch (error) {
      handleClose();
      handlerError(error);
    }
  };

  return (
    <Page
      title={title}
      subTitle={subTitle}
      headerDescription={headerDescription}
      selectedKey={selectedKey}
    >
      {ModalForm && (
        <ButtonContainer>
          <Button type="primary" onClick={() => handleOpen(null)}>
            {addButtonName}
          </Button>
        </ButtonContainer>
      )}
      {children}
      {List && (
        <List
          list={models}
          loading={loading}
          onOpen={handleOpen}
          onAdd={handleAddModel}
          onUpdate={handleUpdateModelList}
          onDelete={handleDelete}
        />
      )}

      {ModalForm && (
        <ModalForm
          id={id}
          path={path}
          visible={showModal}
          onAdd={handleAddModel}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onClose={handleClose}
        />
      )}
    </Page>
  );
}
