import React, { useEffect, useState } from 'react';

import { Row } from 'antd';

import userImgDefault from '../../assets/user.png';
import api from '../../services/api';
import { getIdKey } from '../../services/auth';
import { Header, Thumbnail, ThumbnailContainer } from './styles';

export default function HeaderComponent({
  title,
  subTitle,
  content,
  onBack,
}) {
  const [user, setUser] = useState({});

  useEffect(() => {
    const id = getIdKey();
    const loadUser = async () => {
      const res = await api.get(`/users/${id}`);
      setUser(res.data);
    };

    loadUser();
  }, []);

  return (
    <Header
      ghost={false}
      title={title}
      subTitle={subTitle}
      onBack={onBack}
    >
      <Row>
        <div style={{ flex: 1 }}>{content}</div>
        <ThumbnailContainer>
          <Thumbnail
            src={user && user.thumbnail ? user.thumbnail_url : userImgDefault}
          />
          <strong>{user && user.name}</strong>
        </ThumbnailContainer>
      </Row>
    </Header>
  );
}
