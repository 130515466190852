import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #333;
  padding: 0 60px;
  flex-wrap: wrap;

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    margin: 0 5px;
    border: 1px solid #333;
    padding: 10px;
    cursor: pointer;

    strong {
      font-size: 10pt;
      color: #fff;
      text-transform: uppercase;
    }

    img {
      width: 100%;
      max-width: 80px;
      max-height: 80px;
    }

    ul {
      display: none;
      position: absolute;
      top: 120px;
      left: 0;
      background-color: #333;
      z-index: 9999;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 10px 0;
        color: #fff;
        border-bottom: 1px solid #222;
        width: 200px;
        text-align: center;

        :hover {
          background-color: #222;
        }
      }
    }

    :hover {
      background-color: #222;

      ul {
        display: inline-block;
      }
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 0;

    div {
      width: 100%;
      margin: 0;

      ul {
        display: none;
      }

      :hover {
        ul {
          display: none;
        }
      }
    }
  }
`;

export default Container;
