import React from 'react';

import { Layout } from 'antd';

const { Footer } = Layout;

export default function FooterComponent() {
  return (
    <Footer style={{ background: '#fff', textAlign: 'center', padding: '20px' }}>
      <strong>OAB - Caraguatatuba</strong>
    </Footer>
  );
}
