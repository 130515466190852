/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Image,
  Button,
} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';

function PhotoList() {
  const [photos, setPhotos] = useState([]);
  const [photo, setPhoto] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      let res = await api.get(`/images-gallery/${id}/images`);
      setPhotos(res.data);

      if (res.data && res.data.length === 0) {
        res = await api.get(`/images-gallery/${id}`);
        console.log(res.data);
        setPhoto(res.data);
      }
    };

    load();
  }, [id]);

  const handleGoBack = useCallback(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    navigate('/galerias-fotos');
  }, [navigate]);

  return (
    <>
      <NavBar />
      {photos.length ? (
        <>
          <Carousel>
            {photos.map((slide) => (
              <Carousel.Item key={slide.id}>
                <a
                  href={slide.thumbnail_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="w-100"
                    src={slide.thumbnail_url}
                    alt={slide.name}
                  />
                </a>
              </Carousel.Item>
            ))}
          </Carousel>
          <Container className="justify-content-md-center mt-3" fluid>
            <Row>
              {photos.map((item) => (
                <Col xs={12} md={4} key={item.id}>
                  {(item.thumbnail !== 'undefined' || !item.thumbnail) && (
                    <Card
                      style={{
                        height: '100%',
                        minHeight: '300px',
                        border: '0',
                        marginBottom: '20px',
                      }}
                    >
                      <a
                        href={item.thumbnail_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Card.Img
                          variant="top"
                          src={item.thumbnail_url}
                          style={{
                            borderRadius: '10px',
                          }}
                        />
                      </a>
                    </Card>
                  )}
                </Col>
              ))}
            </Row>
          </Container>
        </>
      ) : (
        <Image className="w-100" src={photo?.thumbnail_url} alt={photo?.name} />
      )}
      <Row className="bg-white justify-content-center p-3">
        <Button onClick={handleGoBack}>
          Voltar
        </Button>
      </Row>
      <Footer />
    </>
  );
}

export default PhotoList;
