import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
  width: 100%;

  strong {
    margin: 0 10px;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 350px;
  }

  @media (max-width: 576px) {
    flex-direction: column;

    strong {
      margin: 10px 0;
    }
  }
`;

export default Container;
