/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Row, Col, ListGroup, Form, Breadcrumb,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { notification } from 'antd';

import ModalForm from '../../../portal/components/AdvocateOpportunityDesk/Modal';
import especialidades from '../../../portal/data/especialidades.json';
import Advocate from '../../components/Cards/Advocate';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';

export default function AdvocatesOpportunityDesk() {
  const [showModal, setShowModal] = useState(false);
  const [advocates, setAdvocates] = useState([]);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState(0);
  const [especialidade, setEspecialidade] = useState(0);
  const navigate = useNavigate();
  const { tipo } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get(
          `advocateOpportunityDesk-filter?search=${
            search || ''
          }&tipo=${tipo}&order=${order}&especialidade=${especialidade}`,
        );
        const { data } = res;
        setAdvocates(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [navigate, search, order, especialidade, tipo]);

  const handleCreate = useCallback((data) => {
    const formData = new FormData();
    formData.append('tipo', tipo);
    formData.append('nome', data.nome);
    formData.append('oab', data.oab);
    formData.append('cpf', data.cpf);
    formData.append('rg', data.rg);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('site', data.site);
    formData.append('whatsapp', data.whatsapp);
    formData.append('telefones', data.telefones);
    formData.append('especialidade', data.especialidade || []);
    formData.append('especialidade_oficial', data.especialidadeOficial || []);
    formData.append('facebook', data.facebook);
    formData.append('instagram', data.instagram);
    formData.append('descricao', data.descricao);
    formData.append('cep', data.cep);
    formData.append('endereco', data.endereco);
    formData.append('numero', data.numero);
    formData.append('bairro', data.bairro);
    formData.append('cidade', data.cidade);
    formData.append('estado', data.estado);
    formData.append('complemento', data.complemento);
    formData.append('file', data.curriculo);

    (async () => {
      try {
        await api.post('/advocateOpportunityDesks', formData);
        notification.success({
          message: 'Cadastrado com sucesso!!!',
        });
        setShowModal(false);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message && typeof message === 'string') {
            notification.error({
              message: 'Falha no Cadastro!!!',
              description: message,
            });

            return;
          }
        }

        notification.error({
          message: 'Falha no Cadastro!!!',
          description: 'Não foi possível realizar o cadastro',
        });
        setShowModal(false);
      }
    })();
  }, [tipo]);

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/servicos">Serviços</Breadcrumb.Item>
              <Breadcrumb.Item active>
                Banco de Oportunidades -
                {' '}
                {`${
                  tipo === 'E'
                    ? 'Estagiários'
                    : tipo === 'A'
                      ? 'Advogados'
                      : 'Correspondentes'
                }`}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1 mb-3">
            <h1>
              Banco de Oportunidades -
              {' '}
              {`${
                tipo === 'E'
                  ? 'Estagiários'
                  : tipo === 'A'
                    ? 'Advogados'
                    : 'Correspondentes'
              }`}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-5 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                onClick={() => setShowModal(true)}
              >
                Cadastrar-se
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://api.whatsapp.com/send?phone=5512981285462"
                target="_blank"
              >
                Nosso Whatsapp
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://www.facebook.com/oabcaragua/?ref=br_rs"
                target="_blank"
              >
                Nosso Facebook
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://www.instagram.com/oab_caraguatatuba/?hl=pt-br"
                target="_blank"
              >
                Nosso Instagram
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <Container>
              <Form>
                <Form.Row>
                  <Col md={4} className="mb-2">
                    <Form.Control
                      placeholder={`${
                        tipo === 'E'
                          ? 'Buscar Estagiários'
                          : tipo === 'A'
                            ? 'Buscar Advogados'
                            : 'Buscar Correspondentes'
                      }`}
                      aria-label={`${
                        tipo === 'E'
                          ? 'Buscar Estagiários'
                          : tipo === 'A'
                            ? 'Buscar Advogados'
                            : 'Buscar Correspondentes'
                      }`}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={especialidade}
                      onChange={(e) => setEspecialidade(e.target.value)}
                    >
                      <option value={0}>Todas Atuações</option>
                      {especialidades.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                    >
                      <option value={0}>Ordenar por Aleatório</option>
                      <option value={1}>Ordenar por Nome</option>
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form>
              {!advocates.length && (
                <Row className="justify-content-center my-3">
                  <p>
                    Nenhum
                    {' '}
                    {`${
                      tipo === 'E'
                        ? 'Estagiário'
                        : tipo === 'A'
                          ? 'Advogado'
                          : 'Correspondente'
                    }`}
                    {' '}
                    encontrado
                  </p>
                </Row>
              )}
              {advocates.map((advocate) => (
                <Row key={advocate._id} className="my-3">
                  <Advocate
                    advocate={advocate}
                    onEspecialidadeClick={(id) => setEspecialidade(id)}
                    onClickTo={`/advogados-banco-oportunidade-dados/${advocate._id}`}
                  />
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ModalForm
        title="Cadastrar-se"
        titleFirstTab={
          tipo === 'E'
            ? 'Dados do Estagiário'
            : tipo === 'A'
              ? 'Dados do Advogado'
              : 'Dados do Correspondente'
        }
        visible={showModal}
        onCreate={handleCreate}
        resetForm={false}
        onClose={() => setShowModal(false)}
        onCloseModal={() => setShowModal(false)}
      />
    </>
  );
}
