import React from 'react';

import { Modal as ModalAntd } from 'antd';

export default function Modal({
  title,
  visible,
  footer,
  children,
  onClose,
}) {
  return (
    <ModalAntd
      title={title}
      visible={visible}
      onCancel={onClose}
      footer={footer}
      width="600px"
      centered
    >
      {children}
    </ModalAntd>
  );
}
