import React from 'react';
import ReactPlayer from 'react-player';

import './YouTubePlayer.css';

export default function YouTubePlayer({
  url,
  width = '100%',
  height = '100%',
  style,
}) {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width={width}
        height={height}
        style={style}
        playing
        light
      />
    </div>
  );
}
