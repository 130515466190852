import React from 'react';

import Page from '../../components/DefaultPage';

export default function Home() {
  return (
    <Page
      title="Home"
      subTitle="Página Inicial"
      selectedKey="0"
    />
  );
}
