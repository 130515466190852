/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { MdEventNote } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import api from '../../services/api';
import './BlogList.css';

export default function List() {
  const [list, setList] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const navigate = useNavigate();
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          backgroundColor: '#333',
          borderRadius: '50%',
        }}
        onClick={onClick}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          backgroundColor: '#333',
          borderRadius: '50%',
        }}
        onClick={onClick}
      />
    );
  };

  useEffect(() => {
    const load = async () => {
      const res = await api.get('/posts');
      setSlidesToShow(res.data.length < 4 ? res.data.length : 4);
      setList(res.data);
    };

    load();
  }, []);

  const responsive = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ];

  return (
    <div className="post-container post-container-bg">
      <p>
        <MdEventNote />
        <strong> ÚLTIMOS POST</strong>
      </p>

      <Slider
        responsive={responsive}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        speed={1000}
        autoplaySpeed={5000}
        autoplay
        infinite
        touchMove
        pauseOnFocus
        dots
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        style={{
          padding: '20px 0',
        }}
      >
        {list.map((post) => (
          <div key={post.id}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(`/blog/${post.id}`)}
            >
              <img
                src={post.thumbnail_url}
                alt={post.thumbnail}
                style={{
                  width: list.length === 1 ? '50%' : '100%',
                  borderRadius: '10%',
                }}
              />
              <div
                style={{
                  padding: '10px',
                }}
              >
                <h3>{post.title}</h3>
                <p>{post.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <Button variant="outline-dark" onClick={() => navigate('/blog')}>
        Mais Posts
      </Button>
    </div>
  );
}
