import React from 'react';

import List from '../../components/Accountability/List';
import Modal from '../../components/Accountability/Modal';
import Page from '../../components/DefaultManager';

export default function Accountability() {
  return (
    <Page
      path="/accountabilities"
      title="Prestação de Contas"
      subTitle="Gerenciador de Prestação de Contas"
      selectedKey="7"
      List={List}
      ModalForm={Modal}
    />
  );
}
