import styled from 'styled-components';

export const Container = styled.div`
  width: 180px;
  padding: 20px;
  border: 1px solid #222;
  border-radius: 10px;
  margin: 10px;
  background-color: #fff;
  cursor: ${(props) => (props.link ? 'pointer' : 'default')};

  div:first-child {
    height: 100px;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: contain;
  }

  a: {
    text-decoration: none;
  }

`;

export default Container;
