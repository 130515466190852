import React from 'react';

import {
  Container,
  CheckboxStyle,
  Checkmark,
  Label,
} from './styles';

export default function Checkbox(props) {
  return (
    <Container>
      <CheckboxStyle
        type="checkbox"
        {...props}
      />
      <Checkmark />
      <Label>{props.label}</Label>
    </Container>
  );
}
