import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '../../../services/api';
import { Container } from './styles';

function Associates() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get('/associate-category');
        setCategories(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Container>
      {categories.map((category) => (
        <div key={category.id}>
          <Link
            to={`/parceiros/categoria/${category.id}`}
            style={{
              textDecoration: 'none',
            }}
          >
            <img alt={category.name} src={category.img_url} />
          </Link>
          <Link
            to={`/parceiros/categoria/${category.id}`}
            style={{
              textDecoration: 'none',
            }}
          >
            <strong>{category.name}</strong>
          </Link>
          <ul>
            {category.subcategories.sort((a, b) => (a.name > b.name ? 1 : -1)).map((subcategory) => (
              <Link
                key={subcategory._id}
                to={`/parceiros/categoria/${category.id}/subcategoria/${subcategory._id}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <li>{subcategory.name}</li>
              </Link>
            ))}
          </ul>
        </div>
      ))}
    </Container>
  );
}

export default Associates;
