import React from 'react';

import * as Yup from 'yup';

import Input from '../Form/Input';
import ModalForm from '../Modal/ModalForm';

export default function Modal(props) {
  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    url: Yup.string().required('A URL é obrigatória'),
    description: Yup.string().required('A descrição é obrigatória'),
  });

  return (
    <ModalForm
      title="Vídeo"
      schemaValidation={schemaValidation}
      {...props}
    >
      <Input
        name="url"
        placeholder="URL do Vídeo no YouTube"
      />
      <Input
        name="description"
        placeholder="Descrição"
      />
    </ModalForm>
  );
}
