import React from 'react';

import { Layout } from 'antd';

import Footer from '../Footer';
import Header from '../Header';
import Menu from '../MenuLeft';
import Content from './styles';

export default function IndexDefault({
  title,
  subTitle,
  headerDescription,
  selectedKey,
  children,
}) {
  return (
    <Layout style={{ minHeight: '100vh' }}>

      <Menu selectedKey={selectedKey} />

      <Layout>
        <Header
          title={title}
          subTitle={subTitle}
          content={headerDescription}
        />

        <Content>
          {children}
        </Content>

        <Footer />
      </Layout>
    </Layout>
  );
}
