import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Row, Col, Accordion, Card, Button, Breadcrumb,
} from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';

import months from '../../../portal/data/months.json';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';
import { FileContainer } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Accountability() {
  const [accountabilities, setAccountabilities] = useState([]);
  const [years, setYears] = useState([]);
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      const res = await api.get('/accountabilities-years');
      const { data } = res;
      setYears(data);
    })();
  }, []);

  const handleMonth = useCallback((year, month) => {
    (async () => {
      const res = await api.get(`/accountabilities-years/${year}/month/${month}`);
      const { data } = res;
      setPage(1);
      setAccountabilities(data);
    })();
  }, []);

  return (
    <>
      <NavBar />
      <Container
        style={{ minHeight: '500px' }}
        className="justify-content-md-center mb-5"
        fluid="md"
      >
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Prestação de Contas</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1">
            <h1>Prestação de Contas</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mt-3">
            <Accordion defaultActiveKey={1}>
              {years.map((year, index) => (
                <Card key={year._id}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={index + 1}
                    style={{ cursor: 'pointer' }}
                    className="bg-dark text-white"
                  >
                    {year._id}
                  </Accordion.Toggle>
                  {year.months.map((month) => (
                    <Accordion.Collapse key={month._id} eventKey={index + 1}>
                      <Card.Body
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleMonth(year._id, month._id)}
                      >
                        {
                          months.find(
                            (monthData) => monthData.value === month._id,
                          ).label
                        }
                      </Card.Body>
                    </Accordion.Collapse>
                  ))}
                </Card>
              ))}
            </Accordion>
          </Col>
          <Col className="mt-4">
            <Container>
              {accountabilities.map((accountability) => (
                <Row className="mb-2" key={accountability.id}>
                  <Col>
                    <FileContainer>
                      <a
                        href={accountability.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h4>{accountability.name}</h4>
                      </a>
                      <div>
                        <Document
                          file={accountability.file_url}
                          error="Não foi possível carregar o pdf"
                          loading="Carregando Pdf"
                          onLoadSuccess={({ numPages }) => {
                            setPages(numPages);
                          }}
                        >
                          <Page pageNumber={page} />
                        </Document>
                      </div>
                      {pages && (
                        <div>
                          <Button
                            variant="dark"
                            onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                          >
                            {'<'}
                          </Button>
                          <small>{`Página ${page} de ${pages}`}</small>
                          <Button
                            variant="dark"
                            onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                          >
                            {'>'}
                          </Button>
                        </div>
                      )}
                    </FileContainer>
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
