import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import localidadeApi from '../../../services/api.localidade';
import { Container } from './styles';

function AssociateCard({ associate }) {
  const [state, setState] = useState();
  const [city, setCity] = useState();

  useEffect(() => {
    (async () => {
      try {
        let res = await localidadeApi.get(`/estados/${associate.state}`);
        setState(res.data);
        res = await localidadeApi.get(`/municipios/${associate.city}`);
        setCity(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [associate]);

  if (associate.link) {
    return (
      <Container src={associate.thumbnail_url} link={associate.link}>
        <a href={associate.link} rel="noopener noreferrer" target="_blank">
          <div />
        </a>
        <h6>{associate.name}</h6>
        <small>{`${city?.nome} - ${state?.sigla}`}</small>
        <p>{associate.benefits}</p>
        {associate.terms ? (
          <p>
            <a
              href={associate.terms_file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="link" size="sm">
                Termo de Parceria
              </Button>
            </a>
          </p>
        ) : null}
      </Container>
    );
  }

  return (
    <Container src={associate.thumbnail_url} link={associate.link}>
      <div />
      <h6>{associate.name}</h6>
      <small>{`${city?.nome} - ${state?.sigla}`}</small>
      <p>{associate.benefits}</p>
      {associate.terms ? (
        <p>
          <a
            href={associate.terms_file_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="link" size="sm">
              Termo de Parceria
            </Button>
          </a>
        </p>
      ) : null}
    </Container>
  );
}

export default AssociateCard;
