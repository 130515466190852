/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const ImageInputContainer = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  margin: 10px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${(props) => (props.preview ? '#2d9dff' : '#fff')};
  background-image: url(${(props) => props.preview});
  border: ${(props) => (
    props.error ? '1px solid #f00'
      : props.preview ? '1px solid #ccc' : '1px dashed #ccc'
  )};
  cursor: pointer;
  img {
    display:  ${(props) => (props.preview ? 'none' : '')}
  }
  small {
    display:  ${(props) => (props.preview ? 'none' : '')}
  }
`;

export default ImageInputContainer;
