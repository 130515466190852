import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  UnorderedListOutlined,
  AccountBookOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { logout } from '../../services/auth';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function MenuLeft({ selectedKey }) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  function onCollapse() {
    setCollapsed((oldstate) => (!oldstate));
  }

  function handleLogout() {
    logout();
    navigate('/');
  }

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <Menu theme="dark" defaultSelectedKeys={[selectedKey]}>
        <Menu.Item key="0">
          <Link to="/portal/home">
            <span>
              <HomeOutlined />
              Home
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to="/portal/blogs">
            <span>
              <UnorderedListOutlined />
              Blog
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/portal/banners">
            <span>
              <UnorderedListOutlined />
              Banner
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/portal/noticias">
            <span>
              <UnorderedListOutlined />
              Notícias
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/portal/prestacao-de-contas">
            <span>
              <AccountBookOutlined />
              Prestação de Contas
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/portal/galeria-fotos">
            <span>
              <UnorderedListOutlined />
              Galeria de Fotos
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/portal/galeria-videos">
            <span>
              <UnorderedListOutlined />
              Galeria de Vídeos
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/portal/advogados">
            <span>
              <UserOutlined />
              Advogados
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="12">
          <Link to="/portal/banco-de-oportunidades">
            <span>
              <UserOutlined />
              Banco de Oportunidades
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/portal/usuarios">
            <span>
              <UserOutlined />
              Usuários
            </span>
          </Link>
        </Menu.Item>
        <SubMenu key="sub1" icon={<UsergroupAddOutlined />} title="Parceiros">
          <Menu.Item key="8">
            <Link to="/portal/parceiros">
              <span>Parceiros</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link to="/portal/parceiros-categorias">
              <span>Categorias</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="11">
            <Link to="/portal/parceiros-destaques">
              <span>Em Destaques</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="13">
          <Link to="/portal/comissoes">
            <span>
              <UnorderedListOutlined />
              Comissões
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item onClick={handleLogout}>
          <span>
            <LogoutOutlined />
            Sair
          </span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
