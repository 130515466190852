import React from 'react';

import Page from '../../components/DefaultManager';
import List from '../../components/List';
import Modal from '../../components/Notice/Modal';

export default function Notice() {
  return (
    <Page
      path="/notices"
      title="Notícias"
      subTitle="Gerenciador de Notícias"
      selectedKey="2"
      List={List}
      ModalForm={Modal}
    />
  );
}
