import React from 'react';

import * as Yup from 'yup';

import Input from '../Form/Input';
import ModalForm from '../Modal/ModalForm';

export default function Modal(props) {
  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      name: Yup.string().required('O nome é obrigatório'),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });
    return validate;
  };

  return (
    <ModalForm
      title="Comissão"
      onSchemaValidation={handleSchemaValidation}
      {...props}
    >
      <Input name="name" placeholder="Nome" />
    </ModalForm>
  );
}
