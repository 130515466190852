import React, { useState } from 'react';

import { Button, notification } from 'antd';

import api from '../../services/api';
import List from '../List';
import Modal from '../Modal/Modal';

export default function AssociateList(props) {
  const [id, setId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [associateTerms, setAssociateTerms] = useState('');

  const handleAssociateTermsSubmit = (event) => {
    event.preventDefault();
    (async () => {
      try {
        if (associateTerms) {
          const formData = new FormData();
          formData.append('file', associateTerms);
          await api.post(`/associateTerms/${id}`, formData);
          notification.success({
            message: 'Mensagem!!!',
            description: 'Termo de parceria cadastrado com sucesso',
          });
          setShowModal(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  };

  const handleAssociateTermsOpen = (dataId) => {
    setId(dataId);
    setShowModal(true);
  };

  return (
    <>
      <List
        {...props}
        onTitle={(item) => item.name}
        onActions={(item) => [
          item.terms ? (
            <a
              href={item.terms_file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary">Termo de Parceria</Button>
            </a>
          ) : (
            <Button
              type="primary"
              onClick={() => handleAssociateTermsOpen(item.id || item._id)}
            >
              Termo de Parceria
            </Button>
          ),
          <Button
            type="primary"
            onClick={() => props.onOpen(item.id || item._id)}
          >
            Alterar
          </Button>,
          <Button
            type="danger"
            onClick={() => props.onDelete(item.id || item._id)}
          >
            Excluir
          </Button>,
        ]}
      />
      <Modal
        visible={showModal}
        footer={<Button onClick={handleAssociateTermsSubmit}>Salvar</Button>}
        onClose={() => setShowModal(false)}
      >
        <p>Selecione um arquivo PDF</p>
        <input
          type="file"
          placeholder="Termo de PDF"
          accept="application/pdf"
          onChange={(e) => setAssociateTerms(e.target.files[0])}
        />
      </Modal>
    </>
  );
}
