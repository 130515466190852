import React from 'react';

import Page from '../../components/DefaultManager';
import List from '../../components/PhotoGallery/List';
import Modal from '../../components/PhotoGallery/Modal';

export default function Gallery() {
  return (
    <Page
      path="/images-gallery"
      title="Galeria de Fotos"
      subTitle="Gerenciador da Galeria de Fotos"
      addButtonName="Criar Album"
      selectedKey="3"
      List={List}
      ModalForm={Modal}
    />
  );
}
