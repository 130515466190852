import React, { useState } from 'react';

import * as Yup from 'yup';

import Checkbox from '../Form/CheckboxControlled';
import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import ModalForm from '../Modal/ModalForm';

export default function Modal(props) {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [internalLink, setInternalLink] = useState(false);

  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      src: Yup.mixed().test({
        name: 'src',
        message: 'Selecione uma imagem ou informe um endereço url da imagem',
        test: () => data.src || data.url,
      }),
      url: Yup.mixed().test({
        name: 'url',
        message: 'Selecione uma imagem ou informe um endereço url da imagem',
        test: () => data.src || data.url,
      }),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });

    return validate;
  };

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('src', data.src);
    formData.append('url', data.url);
    formData.append('link', data.link);
    formData.append('internalLink', internalLink);

    return formData;
  };

  return (
    <ModalForm
      title="Post"
      path="/associate-banners"
      onSchemaValidation={handleSchemaValidation}
      onPreview={setPreviewUrl}
      onFormData={handleFormData}
      {...props}
    >
      <ImageInput name="src" previewUrl={previewUrl} />
      <Input name="url" placeholder="URL da imagem" />
      <Input name="link" placeholder="Link do banner" />
      <Checkbox
        name="internalLink"
        value={internalLink}
        label="É um link interno do site"
        checked={internalLink}
        onChange={() => setInternalLink((prevState) => !prevState)}
      />
    </ModalForm>
  );
}
