import React, { useState, useEffect } from 'react';

import informativocabecalho from '../../assets/informativo-cabecalho.png';
import Footer from '../../components/Footer';
import ReadMore from '../../components/ReadMore';
import api from '../../services/api';
import {
  Container,
  Content,
  NoticesContainer,
  DateContainer,
  MainNotice,
  NoticeItem,
} from './styles';

function Informative() {
  const [notices, setNotices] = useState([]);
  const [mainNotice, setMainNotice] = useState([]);

  useEffect(() => {
    async function loadNotices() {
      const { data } = await api.get('/notices?limit=8&page=1');
      setMainNotice(data.notices[0]);
      data.notices.shift();
      setNotices(data.notices);
    }

    loadNotices();
  }, []);

  if (!notices.length) {
    return null;
  }

  return (
    <Container className="min-vh-100">
      <Content>
        <img alt="Informativo" src={informativocabecalho} />
        <NoticesContainer>
          <DateContainer>
            {new Intl.DateTimeFormat('pt-BR', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(new Date())}
          </DateContainer>
          <MainNotice>
            <a href={`http://oabcaraguatatuba.org/noticia/${mainNotice.id}`}>
              {mainNotice.thumbnail !== 'undefined' || !mainNotice.thumbnail ? (
                <img
                  alt={mainNotice.thumbnail}
                  src={mainNotice.thumbnail_url}
                />
              ) : (
                ''
              )}
            </a>
            <h3>{mainNotice.title}</h3>
            <ReadMore
              link={`http://oabcaraguatatuba.org/noticia/${mainNotice.id}`}
            >
              {mainNotice.content}
            </ReadMore>
          </MainNotice>
          {notices.map((notice) => (
            <NoticeItem>
              <div>
                <a href={`http://oabcaraguatatuba.org/noticia/${notice.id}`}>
                  {notice.thumbnail !== 'undefined' || !notice.thumbnail ? (
                    <img alt={notice.thumbnail} src={notice.thumbnail_url} />
                  ) : (
                    ''
                  )}
                </a>
              </div>
              <div>
                <h5>{notice.title}</h5>
                <ReadMore
                  link={`http://oabcaraguatatuba.org/noticia/${notice.id}`}
                >
                  {notice.content}
                </ReadMore>
              </div>
            </NoticeItem>
          ))}
        </NoticesContainer>
        <Footer />
      </Content>
    </Container>
  );
}

export default Informative;
