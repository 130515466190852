import styled from 'styled-components';

export const FileContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 10px;

  a {
    margin: 20px 0;
  }

  div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
  }

  button {
    border-radius: 50%;
  }

  @media (max-width: 576px) {
    padding: 0;

    div:first-child {
      overflow: scroll;
    }

    div:last-child {
      padding: 0px;
    }
  }
`;

export default FileContainer;
