import { PageHeader } from 'antd';
import styled from 'styled-components';

export const Header = styled(PageHeader)`

`;

export const Thumbnail = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center
`;
