import React, { useState } from 'react';

import * as Yup from 'yup';

import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import ModalForm from '../Modal/ModalForm';

export default function Modal(props) {
  const [previewUrl, setPreviewUrl] = useState(null);

  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    title: Yup.string().required('O título é obrigatório'),
    description: Yup.string().required('A descrição é obrigatória'),
  });

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('thumbnail', data.thumbnail);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('content', data.content);

    return formData;
  };

  return (
    <ModalForm
      title="Galeria de Fotos"
      schemaValidation={schemaValidation}
      onPreview={setPreviewUrl}
      onFormData={handleFormData}
      {...props}
    >
      <ImageInput
        name="thumbnail"
        previewUrl={previewUrl}
        label="Selecione uma imagem para capa da galeria"
      />
      <Input
        name="title"
        placeholder="Título"
      />
      <Input
        name="description"
        placeholder="Descrição"
      />
    </ModalForm>
  );
}
