import React from 'react';

import List from '../../components/Banner/List';
import Modal from '../../components/Banner/Modal';
import Page from '../../components/DefaultManager';

export default function Banner() {
  return (
    <Page
      path="/banners"
      title="Banners"
      subTitle="Gerenciador de Banners"
      selectedKey="10"
      List={List}
      ModalForm={Modal}
    />
  );
}
