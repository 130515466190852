import React, { useState, useEffect } from 'react';
import {
  Image, Container, Row, Col,
} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

import api from '../../services/api';

export default function Post() {
  const { id } = useParams();
  const [post, setPost] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const loadNotice = async () => {
      const res = await api.get(`/posts/${id}`);
      const { data } = res;
      if (data) {
        setPost(res.data);
        return;
      }

      navigate('/');
    };

    loadNotice();
  }, [id, navigate]);

  return (
    <Container className="justify-content-md-center mb-5" fluid="md">
      <Row>
        <Col>
          <h1>{post.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mt-3">
          {
              post.thumbnail !== 'undefined' || !post.thumbnail ? (
                <Image
                  src={post.thumbnail_url}
                  alt={post.thumbnail}
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                  }}
                />
              ) : ''
            }
          <h6 style={{
            textAlign: 'justify',
          }}
          >
            {post.description}

          </h6>
          <p style={{
            textAlign: 'justify',
          }}
          >
            {post.content}
          </p>
        </Col>
      </Row>
    </Container>
  );
}
