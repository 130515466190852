import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, ListGroup, Breadcrumb,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import PaginationComponent from '../../components/Pagination';
import api from '../../services/api';

export default function Galleries() {
  const [notices, setNotices] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    async function loadNotices() {
      const { data } = await api.get(`/notices?limit=20&page=${page}`);
      setNotices(data.notices);
      setHasPrevPage(data.hasPrevPage);
      setHasNextPage(data.hasNextPage);
      setTotalPages(data.totalPages);
    }

    loadNotices();
  }, [page]);

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Comunicação</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1">
            <h1>Comunicação</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mt-3 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="#"
                active
              >
                Notícias
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="/galerias-fotos"
              >
                Galerias de Fotos
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="/videos"
              >
                Vídeos
              </ListGroup.Item>
              {/* <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.oab-sc.org.br/publicacoes#conteudo"
              >
                Publicações
              </ListGroup.Item> */}
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://jornaldaadvocacia.oabsp.org.br"
              >
                Jornal da OAB
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.youtube.com/channel/UC_tM6oxZ6gwI42j-NHAKzHg"
              >
                YouTube
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.instagram.com/oab_caraguatatuba/?hl=pt-br"
              >
                Instagram
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://api.whatsapp.com/send?phone=5512981285462"
              >
                Whatsapp
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.facebook.com/oabcaragua/?ref=br_rs"
              >
                Facebook
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="mt-3 bg-white">
            <Container className="mt-3">
              {notices.map((item) => (
                <Link
                  to={`/noticia/${item.id}`}
                  style={{
                    textDecoration: 'none',
                    color: '#333',
                  }}
                >
                  <Row className="mb-5">
                    <Col xs={2}>
                      {item.thumbnail !== 'undefined' || !item.thumbnail ? (
                        <img
                          src={item.thumbnail_url}
                          alt={item.thumbnail_url}
                          style={{
                            borderRadius: '10px',
                          }}
                          width="100px"
                        />
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col>
                      <h5>{item.title}</h5>
                      <p>{item.description}</p>
                    </Col>
                  </Row>
                </Link>
              ))}
              <Row className="justify-content-center">
                <PaginationComponent
                  page={page}
                  hasNextPage={hasNextPage}
                  hasPrevPage={hasPrevPage}
                  totalPages={totalPages}
                  onPageChange={setPage}
                />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
