import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import List from '../../List';

export default function CategoryList(props) {
  const navigate = useNavigate();
  return (
    <List
      {...props}
      onTitle={(item) => item.name}
      onThumbnail={(item) => item.img_url}
      onActions={(item) => [
        <Button
          onClick={() => navigate(
            `/portal/parceiros-categorias/${item._id}/subcategorias`,
          )}
        >
          Subcategorias
        </Button>,
        <Button
          type="primary"
          onClick={() => props.onOpen(item.id || item._id)}
        >
          Alterar
        </Button>,
        <Button
          type="danger"
          onClick={() => props.onDelete(item.id || item._id)}
        >
          Excluir
        </Button>,
      ]}
    />
  );
}
