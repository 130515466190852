import React from 'react';

import List from '../List';

export default function CommissionList(props) {
  return (
    <List
      {...props}
      onTitle={(item) => item.name}
    />
  );
}
