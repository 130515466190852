import React from 'react';

import List from '../../components/Commission/List';
import Modal from '../../components/Commission/Modal';
import Page from '../../components/DefaultManager';

export default function Category() {
  return (
    <Page
      path="/commissions"
      title="Comissões"
      subTitle="Gerenciador de Comissões"
      selectedKey="13"
      List={List}
      ModalForm={Modal}
    />
  );
}
