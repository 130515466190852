import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// import background from './app/assets/img/background.jpg';
import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'antd/dist/antd.css';
import './app/assets/css/custom.css';

function App() {
  /* return (
    <div
      style={{
        backgroundImage: `url("${background}")`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }}
    >
      <Router>
        <Routes />
      </Router>
    </div>
  ); */

  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
