import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'react-bootstrap';
import { FcSearch } from 'react-icons/fc';
import { Link, useNavigate } from 'react-router-dom';

// import io from 'socket.io-client';

import api from '../../services/api';
// import Pagination from '../Pagination';
import ShareButtons from '../ShareButtons';

export default function NoticeList() {
  const navigate = useNavigate();
  const [notices, setNotices] = useState([]);
  /* const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0); */
  // const navigate = useNavigate();

  /* useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL);
    socket.on('newnotice', (newNotice) => {
      // console.log(newNotice);
      setNotices([newNotice, ...notices]);
      notification.info(newNotice.title, 'Nova Notícia', 10000, () => {
        navigate(`/noticia/${newNotice.id}`);
      });
    });
    socket.on('removenotice', (id) => {
      setNotices(notices.filter((value) => value.id !== id));
    });
  }, [notices]); */

  useEffect(() => {
    async function loadNotices() {
      const { data } = await api.get('/notices?limit=8&page=1');
      setNotices(data.notices);
      /* setHasPrevPage(data.hasPrevPage);
      setHasNextPage(data.hasNextPage);
      setTotalPages(data.totalPages); */
    }

    loadNotices();
  }, []);

  return (
    <Container className="justify-content-md-center" fluid>
      <Row className="mt-3">
        <Col xs={12} md={6} className="mt-4 mt-md-2">
          <Button className="px-2" onClick={() => navigate('/advogados')}>
            <FcSearch
              className="mx-2"
              style={{
                fontSize: '2rem',
                transform: 'scaleX(-1)',
              }}
            />
            Consulte os Advogados e Advogadas inscritos em Caraguatatuba
          </Button>
        </Col>
        <Col xs={12} md={6}>
          <ShareButtons
            title="Compartilhar informativo"
            url="https://oabcaraguatatuba.org.br/informativo"
          />
        </Col>
      </Row>
      <Row
        style={{
          minHeight: '600px',
        }}
      >
        {notices.map((item) => (
          <Col xs={12} md={3} className="my-4" key={item.id}>
            <Link
              to={`/noticia/${item.id}`}
              style={{
                textDecoration: 'none',
                color: '#333',
              }}
            >
              <Card
                style={{
                  height: '100%',
                  minHeight: '300px',
                  border: '0',
                  borderBottom: '3px solid #dddddd',
                }}
              >
                {item.thumbnail !== 'undefined' || !item.thumbnail ? (
                  <Card.Img
                    variant="top"
                    src={item.thumbnail_url}
                    style={{
                      borderRadius: '10px',
                    }}
                  />
                ) : (
                  ''
                )}
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center mb-4">
        {/* <Pagination
          page={page}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          totalPages={totalPages}
          onPageChange={setPage}
        /> */}
        <Button variant="danger" onClick={() => navigate('/comunicacao')}>
          Veja mais Notícia
        </Button>
      </Row>
    </Container>
  );
}
