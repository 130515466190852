import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import List from '../List';

export default function PhotoGalleryList(props) {
  const navigate = useNavigate();
  const handleClick = (event, item) => {
    navigate(`/portal/galeria-fotos/${item.id}`);
  };

  return (
    <List
      {...props}
      onTitle={(item) => (
        <Link
          to={`/galeria-fotos/${item.id}`}
        >
          {item.title}
        </Link>
      )}
      onActions={(item) => (
        [
          <Button type="primary" onClick={(event) => handleClick(event, item)}>Inserir fotos no album</Button>,
          <Button type="primary" onClick={() => props.onOpen(item.id || item._id)}>Alterar</Button>,
          <Button type="danger" onClick={() => props.onDelete(item.id || item._id)}>Excluir</Button>,
        ]
      )}
    />
  );
}
