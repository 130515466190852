import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';

import { useField } from '@unform/core';

import camera from '../../../assets/camera.svg';
import { MsgError } from '../styles';
import { ImageInputContainer } from './styles';

export default function ImageInput({
  name, label = 'Selecione uma Imagem', ...rest
}) {
  const inputRef = useRef(null);

  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);
  const [preview, setPreview] = useState('');

  const handlePreview = useCallback((e) => {
    const file = e.target.files[0];

    if (!file) {
      setPreview(null);
      return;
    }

    setPreview(encodeURI(URL.createObjectURL(file)));
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
        setPreview(null);
      },
      setValue(_ref, value) {
        setPreview(encodeURI(`${process.env.REACT_APP_URL}/files/${value}`));
      },
    });
  }, [fieldName, registerField]);

  return (
    <ImageInputContainer preview={preview} error={!!error}>
      {error && <MsgError>{error}</MsgError>}
      {label && !error && <small>{label}</small>}
      <img
        src={camera}
        alt="Selecione uma Imagem"
      />
      <input
        type="file"
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={handlePreview}
        hidden
        {...rest}
      />
    </ImageInputContainer>
  );
}
