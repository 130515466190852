/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Button } from 'antd';

import List from '../List';

export default function GalleryList(props) {
  return (
    <List
      onTitle={(item) => item.name}
      onDescription={(item) => (
        <a
          href={item.thumbnail_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.thumbnail_url}
        </a>
      )}
      onActions={(item) => (
        [
          <Button type="danger" onClick={() => props.onDelete(item.id || item._id)}>Excluir</Button>,
        ]
      )}
      {...props}
    />
  );
}
