import React from 'react';

import List from '../../components/AdvocateOpportunityDesk/List';
import Modal from '../../components/AdvocateOpportunityDesk/Modal';
import Page from '../../components/DefaultManager';

export default function Advocate() {
  return (
    <Page
      path="/advocateOpportunityDesks"
      title="Banco de Oportunidades"
      subTitle="Gerenciador do Banco de Oportunidades"
      selectedKey="12"
      List={List}
      ModalForm={Modal}
    />
  );
}
