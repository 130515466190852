import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  background: linear-gradient(180deg, #26354f 0, #00597b 100%) !important;
  border-bottom: 4px solid #00597b;
  flex-wrap: wrap;

  div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 50px;
    border-right: 2px solid #00597b;
    padding: 0 10px;
    cursor: pointer;

    a {
      font-size: 10pt;
      color: #fff;
      text-transform: uppercase;
    }

    ul {
      display: none;
      position: absolute;
      top: 120px;
      left: 0;
      background-color: #333;
      z-index: 9999;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 10px 0;
        color: #fff;
        border-bottom: 1px solid #222;
        width: 200px;
        text-align: center;

        :hover {
          background-color: #222;
        }
      }
    }

    :hover {
      background-color: #c52529;

      ul {
        display: inline-block;
      }
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 0;

    div {
      width: 100%;
      height: 50px;
      margin: 0;

      ul {
        display: none;
      }

      :hover {
        ul {
          display: none;
        }
      }
    }
  }
`;

export default Container;
