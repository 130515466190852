import React from 'react';
import {
  Container, Row, Col, ListGroup, Breadcrumb,
} from 'react-bootstrap';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';

export default function Institucional() {
  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Contato</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1">
            <h1>Contato</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mt-3 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="#"
                active
              >
                Contato
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="mt-3 bg-white">
            <Container className="mt-3">
              <Row className="justify-content-center">
                <h1>Em Breve</h1>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
