import React, { useState, useEffect, useCallback } from 'react';
import {
  Image, Container, Row, Col, ListGroup, Breadcrumb,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';

export default function Galleries() {
  const [galleries, setGalleries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadGalleries = async () => {
      const res = await api.get('/images-gallery');
      const { data } = res;
      if (data) {
        setGalleries(res.data);
        return;
      }

      navigate('/');
    };

    loadGalleries();
  }, [navigate]);

  const handleToGallery = useCallback((id) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    navigate(`/galerias-fotos/${id}`);
  }, [navigate]);

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/comunicacao">Comunicação</Breadcrumb.Item>
              <Breadcrumb.Item active>Galeria de Fotos</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1">
            <h1>Galeria de Fotos</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mt-3 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="/comunicacao"
              >
                Notícias
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="#"
                active
              >
                Galerias de Fotos
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="/videos"
              >
                Vídeos
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.oab-sc.org.br/publicacoes#conteudo"
              >
                Publicações
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.oab-sc.org.br/jornal-oab#conteudo"
              >
                Jornal da OAB
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.youtube.com/channel/UC_tM6oxZ6gwI42j-NHAKzHg"
              >
                YouTube
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.instagram.com/oab_caraguatatuba/?hl=pt-br"
              >
                Instagram
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://api.whatsapp.com/send?phone=5512981285462"
              >
                Whatsapp
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                target="_blank"
                action
                href="https://www.facebook.com/oabcaragua/?ref=br_rs"
              >
                Facebook
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="mt-3 bg-white">
            <Container className="mt-3">
              {galleries.map((gallery) => (
                <Row className="mb-5" key={gallery.id}>
                  <Col>
                    <Image
                      src={gallery.thumbnail_url}
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        borderRadius: '10px',
                      }}
                      onClick={() => handleToGallery(gallery.id)}
                    />
                  </Col>
                  <Col>
                    <h4>{gallery.title}</h4>
                    <p>{gallery.description}</p>
                  </Col>
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
