import React from 'react';

import List from '../../components/AssociateBanner/List';
import Modal from '../../components/AssociateBanner/Modal';
import Page from '../../components/DefaultManager';

export default function AssociateBanner() {
  return (
    <Page
      path="/associate-banners"
      title="Parceiro em Destaques"
      subTitle="Gerenciador de Parceiros em Destaques"
      selectedKey="11"
      List={List}
      ModalForm={Modal}
    />
  );
}
