/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const FileInputContainer = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  border: ${(props) => (
    props.error ? '1px solid #f00'
      : props.filename ? '1px solid #ccc' : '1px dashed #ccc'
  )};
  cursor: pointer;
`;

export default FileInputContainer;
