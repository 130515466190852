import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LogoContainer = styled.div`
  width: 60%;
  height: 100%;
  background-color: #e5e5e5;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100vh;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Login = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 50px;
  width: 400px;
  min-height: 200px;
  border-radius: 10px;
  img{
    margin: 10px;
    align-self: center;
  }
`;
