import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';

import * as Yup from 'yup';

import Loader from '../../../app/components/Loading';
import api from '../../../app/services/api';
import localidadeApi from '../../../app/services/api.localidade';
import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import ModalForm from '../Modal/ModalForm';

export default function Modal(props) {
  const [states, setStates] = useState([]);
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await localidadeApi.get('/estados');
      setStates(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get('/associate-category');
        console.log(res.data);
        setCategories(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleChangeStates = useCallback((stateId) => {
    (async () => {
      setState(stateId);
      if (stateId) {
        setLoading(true);
        const res = await localidadeApi.get(`estados/${stateId}/municipios`);
        setCities(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
        setLoading(false);
      }
    })();
  }, []);

  const handleChangeCategories = useCallback((categoryId) => {
    (async () => {
      try {
        setCategory(categoryId);
        const res = await api.get(`/associate-category/${categoryId}`);
        setSubcategories(res.data?.subcategories);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      thumbnail: Yup.mixed().test({
        name: 'thumbnail',
        message: 'Selecione uma imagem',
        test: () => props.id || (!props.id && !!data.thumbnail),
      }),
      name: Yup.string().required('O nome é obrigatório'),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });
    return validate;
  };

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('thumbnail', data.thumbnail);
    formData.append('name', data.name);
    formData.append('link', data.link);
    formData.append('benefits', data.benefits);
    formData.append('state', state);
    formData.append('city', city);
    formData.append('category', category);
    formData.append('subcategory', subcategory);

    return formData;
  };

  const handleSetField = useCallback((_form, data) => {
    (async () => {
      if (data) {
        if (data.state) {
          setState(data.state);
        }

        if (data.city) {
          await handleChangeStates(data.state);
          setCity(data.city);
        }

        if (data.category) {
          setCategory(data.category);
        }

        if (data.subcategory) {
          await handleChangeCategories(data.category);
          setSubcategory(data.subcategory);
        }
      }
    })();
  }, [handleChangeCategories, handleChangeStates]);

  const { onClose } = props;
  return (
    <ModalForm
      {...props}
      title="Parceiro"
      onSetFieldValue={handleSetField}
      onSchemaValidation={handleSchemaValidation}
      onFormData={handleFormData}
      onClose={() => {
        onClose();
        setState('');
        setCity('');
        setCategory('');
        setSubcategory('');
      }}
    >
      <ImageInput name="thumbnail" />
      <Input name="name" placeholder="Nome" />
      <Input name="link" placeholder="Link" />
      <Input name="benefits" placeholder="Benefícios" />
      <Form.Group controlId="state-select">
        <Form.Control
          as="select"
          size="lg"
          value={state}
          onChange={(e) => handleChangeStates(e.target.value)}
        >
          <option value={0}>Estado</option>
          {states.map((s) => (
            <option key={s.id} value={s.id}>
              {s.nome}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Loader spinning={loading}>
        <Form.Group controlId="city-select">
          <Form.Control
            as="select"
            size="lg"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          >
            <option value={0}>Cidade</option>
            {cities.map((c) => (
              <option key={c.id} value={c.id}>
                {c.nome}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Loader>
      <Form.Group controlId="category-select">
        <Form.Control
          as="select"
          size="lg"
          value={category}
          onChange={(e) => handleChangeCategories(e.target.value)}
        >
          <option value="">
            Categoria
          </option>
          {categories.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="subcategory-select">
        <Form.Control
          as="select"
          size="lg"
          value={subcategory}
          onChange={(e) => setSubcategory(e.target.value)}
        >
          <option value="">
            Subcategoria
          </option>
          {subcategories?.map((s) => (
            <option key={s._id} value={s._id}>
              {s.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </ModalForm>
  );
}
