import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from '@unform/web';
import { notification, Button } from 'antd';
import * as Yup from 'yup';

import loginImg from '../../assets/login.png';
import logo from '../../assets/logo.png';
import Input from '../../components/Form/Input';
import api from '../../services/api';
import {
  getToken, setToken, setIdKey, getIdKey,
} from '../../services/auth';
import {
  Container, LogoContainer, LoginContainer, Login,
} from './styles';

export default function SignIn() {
  const navigate = useNavigate();
  useEffect(() => {
    function verifyUser() {
      const token = getToken();
      const id = getIdKey();
      if (token && id) {
        navigate('/portal/home');
      }
    }
    verifyUser();
  });

  const formRef = useRef(null);

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('O email é obrigatório'),
        password: Yup.string().required('A senha é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // validation passed
      const res = await api.post('/authenticate', null, {
        headers: data,
      });
      const { token, user } = res.data;

      setToken(token);
      setIdKey(user.id);

      navigate('/portal/home');
    } catch (error) {
      // Validation failed
      const validationErrors = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
        return;
      }

      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        notification.error({
          message: 'Erro!!!',
          description: message,
        });
      }
    }
  };

  return (
    <>
      <Container>
        <LogoContainer img={logo} />
        <LoginContainer>
          <Login>
            <img src={loginImg} alt="login" />
            <h5 style={{ textAlign: 'center' }}>Login da área admin do site</h5>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <Input
                type="email"
                name="email"
                placeholder="Endereço de e-mail"
              />
              <Input
                type="password"
                name="password"
                placeholder="Senha"
              />
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                Entrar
              </Button>
            </Form>
          </Login>
        </LoginContainer>
      </Container>
    </>
  );
}
