import React from 'react';
import { Pagination } from 'react-bootstrap';

function PaginationComponent({
  page, hasPrevPage, hasNextPage, totalPages, onPageChange,
}) {
  const getPaginationItem = () => {
    const paginationItem = [];
    const interval = 5;
    let start;
    let end;

    if (page < totalPages - interval) {
      start = page;
      end = page + interval;
    } else if (totalPages > interval) {
      start = totalPages - interval;
      end = totalPages;
    } else {
      start = 1;
    }

    for (let i = start; i <= end; i += 1) {
      paginationItem.push(i);
    }

    return paginationItem;
  };

  return (
    <Pagination>
      {page > 1 && <Pagination.First onClick={() => onPageChange(1)} />}
      {hasPrevPage && <Pagination.Prev onClick={() => onPageChange((p) => p - 1)} />}
      {getPaginationItem().map((paginationItem) => (
        <Pagination.Item
          key={paginationItem}
          active={page === paginationItem}
          onClick={() => onPageChange(paginationItem)}
        >
          {paginationItem}
        </Pagination.Item>
      ))}
      {hasNextPage && <Pagination.Next onClick={() => onPageChange((p) => p + 1)} />}
      {page < totalPages && (
        <Pagination.Last onClick={() => onPageChange(totalPages)} />
      )}
    </Pagination>
  );
}

export default PaginationComponent;
