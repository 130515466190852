import React from 'react';
import { useParams } from 'react-router-dom';

import Input from '../../Form/Input';
import ModalForm from '../../Modal/ModalForm';

export default function Modal(props) {
  const { categoryId } = useParams();
  const handleFormData = (data) => ({
    name: data.name,
    category: categoryId,
  });

  return (
    <ModalForm
      title="Subcategoria"
      onFormData={handleFormData}
      {...props}
    >
      <Input name="name" placeholder="Nome" />
    </ModalForm>
  );
}
