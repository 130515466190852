import React, { useState } from 'react';

import * as Yup from 'yup';

import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import Textarea from '../Form/Textarea';
import ModalForm from '../Modal/ModalForm';

export default function Modal({
  id,
  visible,
  onCreate,
  onUpdate,
  onClose,
}) {
  const [previewUrl, setPreviewUrl] = useState(null);

  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    title: Yup.string().required('O título é obrigatório'),
    // description: Yup.string().required('A descrição é obrigatória'),
    content: Yup.string().required('O conteúdo da notícia é obrigatório'),
  });

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('thumbnail', data.thumbnail);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('content', data.content);
    formData.append('link', data.link);

    return formData;
  };

  return (
    <ModalForm
      id={id}
      visible={visible}
      title="Notícia"
      path="/notices"
      schemaValidation={schemaValidation}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onClose={onClose}
      onPreview={setPreviewUrl}
      onFormData={handleFormData}
    >
      <ImageInput
        name="thumbnail"
        previewUrl={previewUrl}
      />
      <Input
        name="title"
        placeholder="Título"
      />
      <Input
        name="description"
        placeholder="Descrição"
      />
      <Input
        name="link"
        placeholder="Link"
      />
      <Textarea
        name="content"
        placeholder="Conteúdo da Notícia"
        rows={4}
      />
    </ModalForm>
  );
}
