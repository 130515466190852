import React from 'react';
import { Link } from 'react-router-dom';

import AssociateSlider from '../components/AssociateSlider';
import BlogList from '../components/Blog/BlogList';
import Footer from '../components/Footer';
import NavBar from '../components/NavBars';
import AssociateNavBar from '../components/NavBars/Associate';
import NoticeList from '../components/Notice/NoticeList';
import Slider from '../components/Slider';
import SocialMediaButtons from '../components/SocialMediaButtons';

export default function Main() {
  return (
    <>
      <SocialMediaButtons />
      <NavBar />
      <Slider />
      <NoticeList />
      <AssociateSlider />
      <BlogList />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '100px',
          marginBottom: '100px',
        }}
      >
        <Link to="/parceiros">
          <h1>Parceiros</h1>
        </Link>
        <AssociateNavBar />
      </div>
      <Footer />
    </>
  );
}
