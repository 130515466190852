/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import filesize from 'filesize';
import { uniqueId } from 'lodash';

import api from '../../services/api';
import FileList from '../DragDrop/FileList';
import Upload from '../DragDrop/Upload';
import ModalForm from '../Modal/Modal';
import { Container, Content } from './styles';

export default function GalleryModal(props) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { id } = useParams();

  const updateFile = (fileId, data) => {
    setUploadedFiles((state) => (
      state.map((uploadedFile) => (
        fileId === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile
      ))
    ));
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();
    data.append('thumbnail', uploadedFile.file, uploadedFile.name);

    api
      .post(`/images-gallery/${id}/images`, data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          updateFile(uploadedFile.id, { progress });
        },
      })
      .then((response) => {
        const { model } = response.data;
        props.onAdd(model);
        updateFile(uploadedFile.id, {
          uploaded: true,
          id: model._id,
          url: model.thumbnail_url,
        });
      })
      .catch(() => {
        updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  const handleUpload = (files = []) => {
    const newFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles([
      ...newFiles,
      ...uploadedFiles,
    ]);

    newFiles.forEach(processUpload);
  };

  return (
    <ModalForm
      title="Selecione as Fotos"
      {...props}
    >
      <Container>
        <Content>
          <Upload onUpload={handleUpload} />
          <FileList files={uploadedFiles} />
        </Content>
      </Container>
    </ModalForm>
  );
}
