import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import oablogo from '../../assets/oab-caragua-logo.png';
import { Container } from './styles';

export default function NavBar() {
  return (
    <>
      <Link to="/">
        <Image
          width="200px"
          src={oablogo}
          alt="OAB"
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '50px',
          }}
        />
      </Link>
      <Container>
        <div>
          <Link
            to="/institucional"
            style={{
              textDecoration: 'none',
            }}
          >
            Institucional
          </Link>
        </div>
        <div>
          <Link
            to="/servicos"
            style={{
              textDecoration: 'none',
            }}
          >
            Serviços
          </Link>
        </div>
        <div>
          <Link
            to="/comissoes"
            style={{
              textDecoration: 'none',
            }}
          >
            Comissões
          </Link>
        </div>
        <div>
          <Link
            to="/comunicacao"
            style={{
              textDecoration: 'none',
            }}
          >
            Comunicação
          </Link>
        </div>
        <div>
          <Link
            to="/contato"
            style={{
              textDecoration: 'none',
            }}
          >
            Contato
          </Link>
        </div>
        <div>
          <Link
            to="/ouvidoria"
            style={{
              textDecoration: 'none',
            }}
          >
            Ouvidoria
          </Link>
        </div>
        <div
          style={{
            backgroundColor: '#c52529',
          }}
        >
          <Link
            to="/prestacao-de-contas"
            style={{
              textDecoration: 'none',
            }}
          >
            Portal da Transparência
          </Link>
        </div>
      </Container>
    </>
  );
}
