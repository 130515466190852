import React, { useState, useCallback } from 'react';

import { notification, Tabs, Divider } from 'antd';
import cep from 'cep-promise';
import * as Yup from 'yup';

import especialidades from '../../data/especialidades.json';
import Checkbox from '../Form/CheckboxControlled';
import Input from '../Form/Input';
import Textarea from '../Form/Textarea';
import ModalForm from '../Modal/ModalForm';

export default function Modal({ onClose, ...props }) {
  const [data, setData] = useState(null);
  const [especialidade, setEspecialidade] = useState([]);
  const [especialidadeOficial, setEspecialidadeOficial] = useState([]);

  const handleSetFieldValue = useCallback((_formRef, d) => {
    setEspecialidade(d.especialidade);
    setEspecialidadeOficial(d.especialidade_oficial);
  }, []);

  const handleAddressByCep = useCallback((e) => {
    const { value } = e.target;
    const loadAddressByCep = async () => {
      try {
        const res = await cep(value);
        setData({
          endereco: res.street,
          bairro: res.neighborhood,
          cidade: res.city,
          estado: res.state,
        });
      } catch (error) {
        // console.log(error);
      }
    };

    loadAddressByCep();
  }, []);

  const handleEspecialidade = useCallback((event) => {
    const { value, checked } = event.target;
    if (checked) {
      if (especialidade.length >= 3) {
        notification.warn({
          message: 'Aviso!!!',
          description: 'Escolha no máximo 3 atuações',
        });
        return;
      }
    }
    const parsedValue = parseInt(value, 10);
    if (checked && !especialidade.includes(parsedValue)) {
      setEspecialidade([parsedValue, ...especialidade]);
      return;
    }

    setEspecialidade(especialidade.filter((id) => id !== parsedValue));
  }, [especialidade]);

  const handleEspecialidadeOficial = useCallback((event) => {
    const { value, checked } = event.target;
    if (checked) {
      if (especialidadeOficial.length >= 3) {
        notification.warn({
          message: 'Aviso!!!',
          description: 'Escolha no máximo 3 especialidades',
        });
        return;
      }
    }
    const parsedValue = parseInt(value, 10);
    if (checked && !especialidadeOficial.includes(parsedValue)) {
      setEspecialidadeOficial([parsedValue, ...especialidadeOficial]);
      return;
    }

    setEspecialidadeOficial(especialidadeOficial.filter((id) => id !== parsedValue));
  }, [especialidadeOficial]);

  const handleSchemaValidation = async (d) => {
    if (!especialidade.length) {
      notification.warn({
        message: 'Aviso!!!',
        description: 'Escolha uma Atuação',
      });
    }

    const schemaValidation = Yup.object().shape({
      // thumbnail: Yup.mixed().required('Selecione uma imagem'),
      nome: Yup.string().required('O nome é obrigatório'),
      oab: Yup.string().required('O número da inscrição oab é obrigatório'),
      cpf: Yup.string().required('O CPF é obrigatório'),
      rg: Yup.string().required('O RG é obrigatório'),
      // cep: Yup.string().required('O CEP é obrigatório'),
      email: Yup.string()
        .email('Informe um email válido')
        .required('O email é obrigatório'),
      password: Yup.string()
        .min(6, 'Informe pelo menos 6 caracteres')
        .required('A senha é obrigatória'),
      especialidade: Yup.mixed().test({
        name: 'especialidade',
        test: () => especialidade.length,
      }),
    });

    const validate = await schemaValidation.validate(d, {
      abortEarly: false,
    });
    return validate;
  };

  const handleFormData = useCallback(
    (d) => ({
      nome: d.nome,
      oab: d.oab,
      cpf: d.cpf,
      rg: d.rg,
      email: d.email,
      password: d.password,
      site: d.site,
      whatsapp: d.whatsapp.replace(/[^\d]+/g, ''),
      telefones: d.telefones,
      especialidade,
      especialidade_oficial: especialidadeOficial,
      facebook: d.facebook,
      instagram: d.instagram,
      descricao: d.descricao,
      cep: d.cep,
      endereco: d.endereco,
      numero: d.numero,
      bairro: d.bairro,
      cidade: d.cidade,
      estado: d.estado,
      complemento: d.complemento,
    }),
    [especialidade, especialidadeOficial],
  );

  return (
    <ModalForm
      title="Advogado"
      addData={data}
      onFormData={handleFormData}
      onSetFieldValue={handleSetFieldValue}
      onSchemaValidation={handleSchemaValidation}
      onClose={onClose ? () => {
        setEspecialidade([]);
        onClose();
      } : null}
      {...props}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Dados do Advogado" key="1" forceRender>
          <Input name="nome" placeholder="Nome" />
          <Input name="oab" placeholder="Número da inscrição OAB" />
          <Input name="cpf" placeholder="CPF" />
          <Input name="rg" placeholder="RG" />
          <Input name="whatsapp" placeholder="WhatsApp" />
          <Input name="telefones" placeholder="Outros Telefones" />
          <Input name="site" placeholder="Site" />
          <Input name="facebook" placeholder="Facebook" />
          <Input name="instagram" placeholder="Instagram" />
          <Textarea name="descricao" placeholder="Breve Descrição" rows={4} />
          <Divider />
          <Input name="email" placeholder="Email/Login" />
          <Input type="password" name="password" placeholder="Senha" />
          <Divider />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Atuações" key="2" forceRender>
          {especialidades.map((e) => (
            <Checkbox
              key={e.id}
              name={`especialidade-${e.id}`}
              value={e.id}
              label={e.nome}
              checked={especialidade.includes(e.id)}
              onChange={handleEspecialidade}
            />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Especialidades" key="3" forceRender>
          {especialidades.map((e) => (
            <Checkbox
              key={e.id}
              name={`especialidade-oficial-${e.id}`}
              value={e.id}
              label={e.nome}
              checked={especialidadeOficial.includes(e.id)}
              onChange={handleEspecialidadeOficial}
            />
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Endereço do Escritório" key="4" forceRender>
          <Input name="cep" placeholder="CEP" onChange={handleAddressByCep} />
          <Input name="endereco" placeholder="Endereço" />
          <Input name="numero" placeholder="Número" />
          <Input name="bairro" placeholder="Bairro" />
          <Input name="cidade" placeholder="Cidade" />
          <Input name="estado" placeholder="Estado" />
          <Input name="complemento" placeholder="Complemento" />
        </Tabs.TabPane>
      </Tabs>
    </ModalForm>
  );
}
