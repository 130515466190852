import React from 'react';
import { useParams } from 'react-router-dom';

import Page from '../../components/DefaultManager';
import List from '../../components/Photo/List';
import Modal from '../../components/Photo/Modal';

export default function Gallery() {
  const { id } = useParams();
  return (
    <Page
      path={`/images-gallery/${id}/images`}
      title="Galeria de Fotos"
      subTitle="Gerenciador da Galeria de Fotos"
      selectedKey="3"
      List={List}
      ModalForm={Modal}
    />
  );
}
