/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Carousel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import api from '../services/api';

export default function Slider() {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    try {
      (async () => {
        const { data } = await api.get('/associate-banners');
        setSliders(data);
      })();
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (sliders.length === 0) {
    return null;
  }

  return (
    <div className="my-5">
      <Row className="justify-content-center">
        <h1>Parceiros em Destaques</h1>
      </Row>
      <Carousel>
        {sliders.map((slide, index) => (
          <Carousel.Item key={index}>
            {slide.link ? (
              slide.internalLink ? (
                <Link to={slide.link}>
                  <img
                    className="w-100"
                    src={slide.url || slide.src_url}
                    alt={slide.url || slide.src_url}
                  />
                </Link>
              ) : (
                <a href={slide.link} target="_blank" rel="noopener noreferrer">
                  <img
                    className="w-100"
                    src={slide.url || slide.src_url}
                    alt={slide.url || slide.src_url}
                  />
                </a>
              )
            ) : (
              <img
                className="w-100"
                src={slide.url || slide.src_url}
                alt={slide.url || slide.src_url}
              />
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
