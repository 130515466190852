import styled from 'styled-components';

export const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 20px 0;
  font-size: 16px;
  color: #1890ff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover{
    span:first-of-type{
      background-color: #bae7ff;
    }
  };
`;

export const CheckboxStyle = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ span:first-of-type{
    background-color: #1890ff;
    transform: scale(1);
    :after{
      content: "";
      position: absolute;
      color: #fff;
    }
  }

  :disabled ~ span:first-of-type{
    background-color: #eee;
    border: 2px solid #c0c0c0;
    transform: scale(1);
    cursor: not-allowed;
  }

  :disabled ~ span:nth-of-type(2){
    color: #c0c0c0;
    cursor: not-allowed;
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #1890ff;
  border-radius: 5px;
  cursor: pointer;

  :after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Label = styled.span`
  color: #1890ff;
  cursor: pointer;
`;
