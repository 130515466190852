import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Accountability from './app/pages/Accountability';
import Advocate from './app/pages/Advocate';
import AdvocateOpportunityDesk from './app/pages/AdvocateOpportunityDesk';
import Advocates from './app/pages/Advocates';
import AdvocatesOpportunityDesk from './app/pages/AdvocatesOpportunityDesk';
import Associate from './app/pages/Associate';
import AssociateCategory from './app/pages/Associate/Category';
import AssociateSubCategory from './app/pages/Associate/SubCategory';
import Blog from './app/pages/Blog';
import Post from './app/pages/Blog/Post';
import Comissoes from './app/pages/Comissoes';
import Comunicacao from './app/pages/Comunicacao';
import Contato from './app/pages/Contato';
import Informative from './app/pages/Informative';
import Institucional from './app/pages/Institucional';
import Home from './app/pages/Main';
import Notice from './app/pages/Notice';
import Ouvidoria from './app/pages/Ouvidoria';
import PhotoGallery from './app/pages/PhotoGallery';
import PhotoList from './app/pages/PhotoGallery/PhotoList';
import Servicos from './app/pages/Servicos';
import VideoGallery from './app/pages/VideoGallery';
import PrivateRoute from './portal/components/PrivateRoute';
import PortalAccountability from './portal/pages/Accountability';
import PortalAdvocate from './portal/pages/Advocate';
import PortalAdvocateOpportunityDesk from './portal/pages/AdvocateOpportunityDesk';
import PortalAssociate from './portal/pages/Associate';
import PortalAssociateCategory from './portal/pages/Associate/Category';
import PortalAssociateSubCategory from './portal/pages/Associate/SubCategory';
import PortalAssociateBanners from './portal/pages/AssociateBanner';
import PortalBanner from './portal/pages/Banner';
import PortalBlog from './portal/pages/Blog';
import PortalCommission from './portal/pages/Commission';
import Portal from './portal/pages/Home';
import PortalNotice from './portal/pages/Notice';
import PortalPhotoGallery from './portal/pages/PhotoGallery';
import PortalPhotos from './portal/pages/PhotoGallery/Photos';
import PortalSignIn from './portal/pages/SignIn';
import PortalUser from './portal/pages/User';
import PortalVideoGallery from './portal/pages/VideoGallery';

export default function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/noticia/:id" element={<Notice />} />
      <Route path="/informativo" element={<Informative />} />
      <Route path="blog" element={<Blog />}>
        <Route path=":id" element={<Post />} />
      </Route>
      <Route path="/institucional" element={<Institucional />} />
      <Route path="/servicos" element={<Servicos />} />
      <Route path="/comissoes" element={<Comissoes />} />
      <Route path="/comunicacao" element={<Comunicacao />} />
      <Route path="/contato" element={<Contato />} />
      <Route path="/ouvidoria" element={<Ouvidoria />} />
      <Route path="/prestacao-de-contas" element={<Accountability />} />
      <Route path="/galerias-fotos" element={<PhotoGallery />} />
      <Route path="/galerias-fotos/:id" element={<PhotoList />} />
      <Route path="/videos" element={<VideoGallery />} />
      <Route path="/advogados" element={<Advocates />} />
      <Route path="/advogados/:id" element={<Advocate />} />
      <Route
        path="/advogados-banco-oportunidade/:tipo"
        element={<AdvocatesOpportunityDesk />}
      />
      <Route
        path="/advogados-banco-oportunidade-dados/:id"
        element={<AdvocateOpportunityDesk />}
      />
      <Route path="/parceiros" element={<Associate />} />
      <Route path="/parceiros/categoria/:id" element={<AssociateCategory />} />
      <Route
        path="/parceiros/categoria/:categoryId/subcategoria/:subcategoryId"
        element={<AssociateSubCategory />}
      />

      {/* routas do portal */}
      <Route path="/portal" element={<PortalSignIn />} />
      <PrivateRoute path="/portal/home" element={<Portal />} />
      <PrivateRoute path="/portal/blogs" element={<PortalBlog />} />
      <PrivateRoute path="/portal/banners" element={<PortalBanner />} />
      <PrivateRoute path="/portal/noticias" element={<PortalNotice />} />
      <PrivateRoute
        path="/portal/prestacao-de-contas"
        element={<PortalAccountability />}
      />
      <PrivateRoute
        path="/portal/galeria-fotos"
        element={<PortalPhotoGallery />}
      />
      <PrivateRoute
        path="/portal/galeria-fotos/:id"
        element={<PortalPhotos />}
      />
      <PrivateRoute
        path="/portal/galeria-videos"
        element={<PortalVideoGallery />}
      />
      <PrivateRoute path="/portal/advogados" element={<PortalAdvocate />} />
      <PrivateRoute
        path="/portal/banco-de-oportunidades"
        element={<PortalAdvocateOpportunityDesk />}
      />
      <PrivateRoute path="/portal/usuarios" element={<PortalUser />} />
      <PrivateRoute path="/portal/comissoes" element={<PortalCommission />} />
      <PrivateRoute path="/portal/parceiros" element={<PortalAssociate />} />
      <PrivateRoute
        path="/portal/parceiros-categorias"
        element={<PortalAssociateCategory />}
      />
      <PrivateRoute
        path="/portal/parceiros-destaques"
        element={<PortalAssociateBanners />}
      />
      <PrivateRoute
        path="/portal/parceiros-categorias/:categoryId/subcategorias"
        element={<PortalAssociateSubCategory />}
      />
    </Routes>
  );
}
