import React from 'react';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LinkedinShareButton,
  LinkedinIcon,
  LivejournalShareButton,
  LivejournalIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import { Container } from './styles';

function ShareInformative({ title, url }) {
  return (
    <Container>
      <strong>{title}</strong>
      <div>
        <EmailShareButton url={url}>
          <EmailIcon size={36} round />
        </EmailShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon size={36} round />
        </FacebookShareButton>
        <InstapaperShareButton url={url}>
          <InstapaperIcon size={36} round />
        </InstapaperShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={36} round />
        </LinkedinShareButton>
        <LivejournalShareButton url={url}>
          <LivejournalIcon size={36} round />
        </LivejournalShareButton>
        <TelegramShareButton url={url}>
          <TelegramIcon size={36} round />
        </TelegramShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon size={36} round />
        </TwitterShareButton>
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={36} round />
        </WhatsappShareButton>
      </div>
    </Container>
  );
}

export default ShareInformative;
