import React from 'react';

import * as Yup from 'yup';

import ImageInput from '../../Form/ImageInput';
import Input from '../../Form/Input';
import ModalForm from '../../Modal/ModalForm';

export default function Modal(props) {
  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      img: Yup.mixed().test({
        name: 'img',
        message: 'Selecione um ícone para categoria',
        test: () => props.id || (!props.id && !!data.img),
      }),
      name: Yup.string().required('O nome é obrigatório'),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });
    return validate;
  };

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('img', data.img);
    formData.append('imgheader', data.imgheader);
    formData.append('name', data.name);

    return formData;
  };

  return (
    <ModalForm
      title="Categoria"
      onSchemaValidation={handleSchemaValidation}
      onFormData={handleFormData}
      {...props}
    >
      <ImageInput label="Ícone" name="img" />
      <ImageInput label="Imagem" name="imgheader" />
      <Input name="name" placeholder="Nome" />
    </ModalForm>
  );
}
