import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #a1a1a1;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #fff

  h5 {
    color: #b02d26;
  }

  p {
    text-align: justify;
  }

  span:first-child {
    margin: 5px 0;
  }

  span {
    font-size: 10pt;
    color: #757b8a;
    cursor: pointer;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    margin: 5px 0;
    color: #222;

    svg {
      margin-right: 5px;
    }
  }

  a:link,
  a:visited,
  a:active,
  a:hover {
    color: #222;
  }
`;

export default Container;
