import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  background-color: #808080;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;

  h1, p {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    font-size: 12pt;
    font-weight: 500;
  }
`;

export default Container;
