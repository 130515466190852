import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Row, Col, ListGroup, Form, Breadcrumb,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { notification } from 'antd';

import ModalForm from '../../../portal/components/Advocate/Modal';
import especialidades from '../../../portal/data/especialidades.json';
import Advocate from '../../components/Cards/Advocate';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';
import api from '../../services/api';

export default function Advocates() {
  const [showModal, setShowModal] = useState(false);
  const [advocates, setAdvocates] = useState([]);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState(0);
  const [especialidade, setEspecialidade] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get(`advocates-filter?search=${search || ''}&order=${order}&especialidade=${especialidade}`);
        const { data } = res;
        setAdvocates(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [navigate, search, order, especialidade]);

  const handleCreate = useCallback((data) => {
    (async () => {
      try {
        await api.post('/advocates', data);
        notification.success({
          message: 'Cadastrado com sucesso!!!',
          description: 'Aguarde a aprovação para ser relacionado nas buscas',
        });
        setShowModal(false);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message && typeof message === 'string') {
            notification.error({
              message: 'Falha no Cadastro!!!',
              description: message,
            });

            return;
          }
        }

        notification.error({
          message: 'Falha no Cadastro!!!',
          description: 'Não foi possível realizar o cadastro',
        });
        setShowModal(false);
      }
    })();
  }, []);

  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Advogados</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1 mb-3">
            <h1>
              Consulte os Advogados e Advogadas inscritos em Caraguatatuba
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-5 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                onClick={() => setShowModal(true)}
              >
                Cadastrar-se
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://api.whatsapp.com/send?phone=5512981285462"
                target="_blank"
              >
                Nosso Whatsapp
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://www.facebook.com/oabcaragua/?ref=br_rs"
                target="_blank"
              >
                Nosso Facebook
              </ListGroup.Item>
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href="https://www.instagram.com/oab_caraguatatuba/?hl=pt-br"
                target="_blank"
              >
                Nosso Instagram
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col>
            <Container>
              <Form>
                <Form.Row>
                  <Col md={4} className="mb-2">
                    <Form.Control
                      placeholder="Buscar Advogado"
                      aria-label="Buscar Advogado"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={especialidade}
                      onChange={(e) => setEspecialidade(e.target.value)}
                    >
                      <option value={0}>Todas Atuações</option>
                      {especialidades.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                    >
                      <option value={0}>Ordenar por Aleatório</option>
                      <option value={1}>Ordenar por Nome</option>
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form>
              {!advocates.length && (
                <Row className="justify-content-center my-3">
                  <p>Nenhum Advogado encontrado</p>
                </Row>
              )}
              {advocates.map((advocate) => (
                <Row key={advocate._id} className="my-3">
                  <Advocate
                    advocate={advocate}
                    onEspecialidadeClick={(id) => setEspecialidade(id)}
                  />
                </Row>
              ))}
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ModalForm
        title="Cadastrar-se"
        visible={showModal}
        onCreate={handleCreate}
        resetForm={false}
        onClose={() => setShowModal(false)}
        onCloseModal={() => setShowModal(false)}
      />
    </>
  );
}
