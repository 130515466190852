import React from 'react';

import { Button } from 'antd';

import List from '../List';

function ListComponent(props) {
  return (
    <List
      {...props}
      onTitle={(item) => item.src_url || item.url || item.src}
      onDescription={(item) => item.link}
      onThumbnail={(item) => item.url || item.src_url}
      onActions={(item) => [
        <Button
          type="danger"
          onClick={() => props.onDelete(item.id || item._id)}
        >
          Excluir
        </Button>,
      ]}
    />
  );
}

export default ListComponent;
