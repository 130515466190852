import React from 'react';
import {
  Container, Row, Col, ListGroup, Breadcrumb,
} from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBars';

export default function Ouvidoria() {
  return (
    <>
      <NavBar />
      <Container className="justify-content-md-center mb-5" fluid="md">
        <Row>
          <Col className="mt-3">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Ouvidoria</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col className="bg-white mt-1">
            <h1>Ouvidoria</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mt-3 bg-light">
            <ListGroup variant="flush">
              <ListGroup.Item
                className="bg-light text-dark"
                variant="dark"
                action
                href=""
                active
              >
                Ouvidoria
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="mt-3 bg-white">
            <Container className="mt-3">
              <Row className="justify-content-center">
                <Col>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <a
                      className="text-dark"
                      href="mailto:ouvidoria.65@oabsp.org.br"
                    >
                      <FaEnvelope
                        style={{ fontSize: '3rem' }}
                      />
                    </a>
                    <br />
                    <a
                      className="text-danger"
                      href="mailto:ouvidoria.65@oabsp.org.br"
                      style={{ fontWeight: '700' }}
                    >
                      ouvidoria.65@oabsp.org.br
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
